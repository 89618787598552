import React from "react";
import { connect } from "react-redux";
import {
  addPOSFilter,
  removePOSFilter
} from "../../actions/components/searchFilterActions";
import uuid from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const POSFilter = props => {
  const {
    addPOSFilter,
    appliedFilters,
    bodyID,
    controlFilter,
    controlFilterToggle,
    headingID,
    name,
    removePOSFilter,
    toggleValue,
    values
  } = props;

  const handleAppliedFilter = filter => {
    if (appliedFilters.includes(filter)) {
      return removePOSFilter(filter).then(() => controlFilter());
    }
    return addPOSFilter(filter).then(() => controlFilter());
  };

  return (
    <div key={uuid()}>
      <hr className="filter__hr" />
      <dt role="heading" className="filter__category">
        <button
          aria-controls={bodyID}
          aria-expanded={toggleValue}
          className="filter__accordion-btn"
          id={headingID}
          onClick={() => controlFilterToggle("POSFilter")}
        >
          {toggleValue && <FontAwesomeIcon className="mr-15" icon="times" />}
          {!toggleValue && <FontAwesomeIcon className="mr-15" icon="plus" />}
          <span className="bold--md">{name}</span>
        </button>
      </dt>
      {toggleValue && (
        <dd
          aria-labelledby={headingID}
          className="filter__btn-container"
          id={bodyID}
        >
          {values.map(filter => (
            <button
              aria-pressed={appliedFilters.includes(filter)}
              className={`
                  filter-btn
                  bold--sm
                  mr-15
                  mt-15
                  ${appliedFilters.includes(filter) && "filter-btn--selected"}
                `}
              key={uuid()}
              onClick={() => handleAppliedFilter(filter)}
            >
              {filter}
            </button>
          ))}
        </dd>
      )}
    </div>
  );
};

POSFilter.propTypes = {
  controlFilter: PropTypes.func.isRequired,
  controlFilterToggle: PropTypes.func.isRequired,
  toggleValue: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  appliedFilters: state.searchFilter.POSFilter.appliedFilters,
  bodyID: state.searchFilter.POSFilter.bodyID,
  headingID: state.searchFilter.POSFilter.headingID,
  name: state.searchFilter.POSFilter.name,
  values: state.searchFilter.POSFilter.values
});

const mapDispatchToProps = dispatch => ({
  addPOSFilter: async value => dispatch(addPOSFilter(value)),
  removePOSFilter: async value => dispatch(removePOSFilter(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(POSFilter);
