import { DictionaryActions } from "../../actions/types";
const {
  DICTIONARY_RESET,
  DICTIONARY_SEARCH_VALUE,
  DICTIONARY_UPDATE_TOGGLE_FILTER
} = DictionaryActions;

const initialState = {
  searchValue: "",
  toggleFilter: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DICTIONARY_RESET:
      return {
        ...state,
        searchValue: "",
        toggleFilter: false
      };
    case DICTIONARY_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload
      };
    case DICTIONARY_UPDATE_TOGGLE_FILTER:
      return {
        ...state,
        toggleFilter: action.payload
      };
    default:
      return state;
  }
}
