export const urlValidator = (value) => {
  try {
    if (new URL(value)) return value;
  } catch (e) {
    return `//${value}`;
  }
};

export const HTTP200Checker = (value) => {
  const str = value.toString().charAt(0);
  if (str === "2") {
    return true;
  } else {
    return false;
  }
};

export const apiParser = (
  param1,
  { param2 = "", param3 = "", devParam = "" } = {}
) => {
  // return development api
  if (
    process.env.REACT_APP_DEV_HARDSET === "true" ||
    process.env.NODE_ENV === "development"
  ) {
    const API_BASE_URL = process.env.REACT_APP_BASE_API_DEV;
    const API_VERSION = process.env.REACT_APP_DEV_VERSION;
    const API_HASH_DEV = process.env.REACT_APP_HASH_DEV;
    
    return `${API_BASE_URL}/${API_VERSION}/${param1}${
      param2 ? "/" + param2 : ""
    }${param3 ? "/" + param3 : ""}${devParam ? devParam : "?"}${API_HASH_DEV}`;
  }
  // return production api
  else {
    const API_BASE_URL = process.env.REACT_APP_BASE_API_PROD;
    const API_VERSION = process.env.REACT_APP_VERSION;

    return `${API_BASE_URL}/${API_VERSION}/${param1}${param2 && "/" + param2}${
      param3 && "/" + param3
    }`;
  }
};

export const submissionMSG = (action, name, data) => {
  const valid = HTTP200Checker(data.status);
  const message = valid
    ? `${name} successfully ${action}!`
    : `${name} unable to be ${action}, please contact webmaster@ufl.edu with the following details.
  Error code: ${data.status}, ${data.statusText}. Internal message: ${data.data.MSG}`;
  const type = valid ? "success" : "error";

  return {
    message,
    type,
    status: data.status,
    statusText: data.statusText,
  };
};
