import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const AssignmentCards = (props) => {
  let mobile = false;

  if (window.matchMedia("(max-width: 1024px)").matches) {
    mobile = true;
  }

  if (mobile) {
    return (
      <>
        {props.items.map((item, index) => (
          <div key={`item${index}`}>
            <h2 className="mt-50 ">{`Module: ${item.label}`}</h2>
            <div className="assignment__container ">
              {item.assignments.map((assignment, i) => (
                <div
                  className="assignment__card"
                  key={`item${index}-assignment${i}`}
                >
                  <div className="assignment__card-header-container ">
                    <p className="assignment__card-header bold--md">
                      {assignment.name}
                    </p>
                  </div>
                  <div className="assignment__card-body">
                    <p className="mb-5">
                      <span className="bold--sm">Type: </span>
                      <span className="reg--sm">{assignment.type}</span>
                    </p>
                    <p className="mb-15">
                      <span className="bold--sm">Grade: </span>
                      <span className="reg--sm">{assignment.grade}</span>
                    </p>

                    <div className="inset mb-15">
                      <p className="mb-5">
                        <span className="bold--sm">Start Date: </span>
                        <span className="reg--sm">
                          {assignment.start
                            ? moment
                                .unix(assignment.start / 1000)
                                .format("MMM DD, YYYY")
                            : "N/A"}
                        </span>
                      </p>
                      <p className="m-0">
                        <span className="bold--sm">Due Date: </span>
                        <span className="reg--sm">
                          {assignment.due
                            ? moment
                                .unix(assignment.due / 1000)
                                .format("MMM DD, YYYY")
                            : "N/A"}
                        </span>
                      </p>
                    </div>

                    <Link
                      className="primary-btn bold--md assignment__card-link mt-15"
                      to={{
                        pathname: `/assignment`,
                        search: `?c=${encodeURIComponent(
                          props.courseid
                        )}&s=${encodeURIComponent(
                          props.sectionid
                        )}&a=${encodeURIComponent(assignment.id)}`,
                      }}
                    >
                      <span className="mr-10">Next</span>
                      <FontAwesomeIcon icon="arrow-right" />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </>
    );
  }
  return (
    <>
      {props.items.map((item, index) => (
        <div key={`item${index}`}>
          <h2 className="mt-50">{`Module: ${item.label}`}</h2>
          <div className="assignment__container">
            {item.assignments.map((assignment, i) => (
              <div
                className="assignment__card"
                key={`item${index}-assignment${i}`}
              >
                <div className="assignment__card-header-container">
                  <p className="assignment__card-header bold--md">
                    {assignment.name}
                  </p>
                </div>
                <div className="assignment__card-body">
                  <p className="mb-5">
                    <span className="bold--sm">Type: </span>
                    <span className="reg--sm">{assignment.type}</span>
                  </p>
                  <p className="mb-15">
                    <span className="bold--sm">Grade: </span>
                    <span className="reg--sm">{assignment.grade}</span>
                  </p>

                  <div className="inset mb-15">
                    <p className="mb-5">
                      <span className="bold--sm">Start Date: </span>
                      <span className="reg--sm">
                        {assignment.start
                          ? moment
                              .unix(assignment.start / 1000)
                              .format("MMM DD, YYYY")
                          : "N/A"}
                      </span>
                    </p>
                    <p className="m-0">
                      <span className="bold--sm">Due Date: </span>
                      <span className="reg--sm">
                        {assignment.due
                          ? moment
                              .unix(assignment.due / 1000)
                              .format("MMM DD, YYYY")
                          : "N/A"}
                      </span>
                    </p>
                  </div>

                  <Link
                    className="primary-btn bold--md assignment__card-link mt-15"
                    to={{
                      pathname: `/assignment`,
                      search: `?c=${encodeURIComponent(
                        props.courseid
                      )}&s=${encodeURIComponent(
                        props.sectionid
                      )}&a=${encodeURIComponent(assignment.id)}`,
                    }}
                  >
                    <span className="mr-10">Next</span>
                    <FontAwesomeIcon icon="arrow-right" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

AssignmentCards.propTypes = {
  items: PropTypes.array.isRequired,
};

export default AssignmentCards;
