import React from "react";
import { connect } from "react-redux";
import {
  addAlphabeticalFilter,
  removeAlphabeticalFilter
} from "../../actions/components/searchFilterActions";
import uuid from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const AlphabeticalFilter = props => {
  const {
    addAlphabeticalFilter,
    appliedFilters,
    bodyID,
    controlFilter,
    controlFilterToggle,
    headingID,
    name,
    removeAlphabeticalFilter,
    toggleValue,
    values
  } = props;

  const handleAppliedFilter = filter => {
    if (appliedFilters.includes(filter)) {
      return removeAlphabeticalFilter().then(() => controlFilter());
    }
    return addAlphabeticalFilter(filter).then(() => controlFilter());
  };

  return (
    <div key={uuid()}>
      <hr className="filter__hr" />
      <dt role="heading" className="filter__category">
        <button
          aria-controls={bodyID}
          aria-expanded={toggleValue}
          className="filter__accordion-btn"
          id={headingID}
          onClick={() => controlFilterToggle("AlphabeticalFilter")}
        >
          {toggleValue && <FontAwesomeIcon className="mr-15" icon="times" />}
          {!toggleValue && <FontAwesomeIcon className="mr-15" icon="plus" />}
          <span className="bold--md">{name}</span>
        </button>
      </dt>
      {toggleValue && (
        <dd
          aria-labelledby={headingID}
          className="filter__btn-container"
          id={bodyID}
        >
          {values.map(filter => (
            <button
              aria-pressed={appliedFilters.includes(filter)}
              className={`
                  filter-btn
                  bold--sm
                  mr-15
                  mt-15
                  ${appliedFilters.includes(filter) && "filter-btn--selected"}
                `}
              key={uuid()}
              onClick={() => handleAppliedFilter(filter)}
            >
              {filter}
            </button>
          ))}
        </dd>
      )}
    </div>
  );
};

AlphabeticalFilter.propTypes = {
  controlFilter: PropTypes.func.isRequired,
  controlFilterToggle: PropTypes.func.isRequired,
  toggleValue: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  appliedFilters: state.searchFilter.AlphabeticalFilter.appliedFilters,
  bodyID: state.searchFilter.AlphabeticalFilter.bodyID,
  headingID: state.searchFilter.AlphabeticalFilter.headingID,
  name: state.searchFilter.AlphabeticalFilter.name,
  values: state.searchFilter.AlphabeticalFilter.values
});

const mapDispatchToProps = dispatch => ({
  addAlphabeticalFilter: async value => dispatch(addAlphabeticalFilter(value)),
  removeAlphabeticalFilter: async () => dispatch(removeAlphabeticalFilter())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlphabeticalFilter);
