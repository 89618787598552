import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabList from "../TabList";
import WordInfo from "../WordInfo";
import TwoColumnList from "../TwoColumnList";

const BackBTN = (props) => (
  <button
    type="button"
    className="bold--md link-btn--light mb-50"
    onClick={props.controlReset}
  >
    <FontAwesomeIcon icon="arrow-left" /> BACK TO START LANGUAGE
  </button>
);

class VocabList extends Component {
  state = {
    selectedWord: "",
    notFound: false,
  };

  handleSearchUpdate = (word) => this.setState({ selectedWord: word });

  handleReset = () => this.setState({ selectedWord: "", notFound: false });

  handleNotFound = (value) => this.setState({ notFound: value });

  render() {
    const { notFound, selectedWord } = this.state;
    const { vocab } = this.props.assignment;

    if (notFound) {
      return (
        <React.Fragment>
          <BackBTN controlReset={this.handleReset} />
          <p className="bold--md">Error: unable to lookup word.</p>
        </React.Fragment>
      );
    }

    if (this.props.assignmentType === "vocab list") {
      return (
        <>
          {selectedWord ? (
            <>
              <BackBTN controlReset={this.handleReset} />
              <WordInfo
                selectedWord={selectedWord}
                controlNotFound={this.handleNotFound}
              />
            </>
          ) : (
            <TabList tabHeaders={["Actions", "Actors", "Modalities"]}>
              <TwoColumnList
                list={vocab.verblist}
                controlSearchUpdate={this.handleSearchUpdate}
                bottomGrid={this.props.bottomGrid}
                key={this.props.genericToggle}
              />

              <TwoColumnList
                list={vocab.nounlist}
                controlSearchUpdate={this.handleSearchUpdate}
                bottomGrid={this.props.bottomGrid}
                key={this.props.genericToggle}
              />

              <TwoColumnList
                list={vocab.modifierlist}
                controlSearchUpdate={this.handleSearchUpdate}
                bottomGrid={this.props.bottomGrid}
                key={this.props.genericToggle}
              />
            </TabList>
          )}
        </>
      );
    }

    return (
      <>
        {selectedWord ? (
          <>
            <BackBTN controlReset={this.handleReset} />
            <WordInfo
              selectedWord={selectedWord}
              controlNotFound={this.handleNotFound}
            />
          </>
        ) : (
          <TabList tabHeaders={["Actors", "Actions", "Modalities"]}>
            <TwoColumnList
              list={vocab.nounlist}
              controlSearchUpdate={this.handleSearchUpdate}
              bottomGrid={this.props.bottomGrid}
              key={this.props.genericToggle}
            />

            <TwoColumnList
              list={vocab.verblist}
              controlSearchUpdate={this.handleSearchUpdate}
              bottomGrid={this.props.bottomGrid}
              key={this.props.genericToggle}
            />

            <TwoColumnList
              list={vocab.modifierlist}
              controlSearchUpdate={this.handleSearchUpdate}
              bottomGrid={this.props.bottomGrid}
              key={this.props.genericToggle}
            />
          </TabList>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  assignment: state.get.assignment,
  genericToggle: state.genericToggle.genericToggle,
  assignmentType: state.get.assignment.type,
});

export default connect(mapStateToProps)(VocabList);
