import React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

Modal.setAppElement("#root");

class EditModal extends React.Component {
  closeModal = () => {
    this.props.closeModal();
  };

  handleUpdate = () => {
    this.props.controlUpdate();
    this.props.closeModal();
  };

  handleSubmit = (e) => {
    const key = e.which;

    if (key === 13) {
      this.handleUpdate();
    }
  };

  render() {
    return (
      <div>
        <Modal
          closeTimeoutMS={150}
          isOpen={this.props.openModal}
          overlayClassName='ReactModal__Overlay'
          className='ReactModal__Content ReactModal__message-content'
          onRequestClose={this.closeModal}
          contentLabel='Edit Word'>
          <h2 className='bold--lg mb-5'>{this.props.title}</h2>
          {this.props.desc && (
            <p>
              <FontAwesomeIcon className='mr-5' icon='info-circle' />{" "}
              {this.props.desc}
            </p>
          )}
          <input
            onKeyPress={this.handleSubmit}
            onChange={(e) => this.props.updateText(e.target.value)}
            className='reg--lg default mt-25'
            type='text'
            value={this.props.text}
          />
          <div className='edit-word-btn-container'>
            <button
              onClick={this.closeModal}
              className='secondary-btn bold--md edit-word-btn'>
              Cancel
            </button>
            <button
              onClick={this.handleUpdate}
              className='primary-btn bold--md edit-word-btn'>
              {this.props.btnText}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

EditModal.propTypes = {
  title: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  desc: PropTypes.string,
  text: PropTypes.string.isRequired,
  updateText: PropTypes.func.isRequired,
  controlUpdate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
};

export default EditModal;
