import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotFound = (props) => {
  let goBack = props.history.goBack();
  document.title = "404: Page Not Found - Grammar Cruncher";

  return (
    <div className='error-page'>
      <div className='container center-children full-screen-height'>
        <section className='m-auto'>
          <h1>Hmm, that page doesn’t seem to exist.</h1>
          <div className='bold--md'>
            Not to worry though! You can either go back to your previously
            visited page or the home page.
          </div>
          <a
            className='secondary-btn bold--md mt-50 mr-25'
            // href="javascript:history.back()"
            href={goBack}>
            <FontAwesomeIcon className='mr-15' icon='arrow-left' />
            Previous Page
          </a>
          <NavLink className='primary-btn bold--md' exact to='/'>
            <FontAwesomeIcon className='mr-15' icon='home' />
            Home
          </NavLink>
        </section>
      </div>
    </div>
  );
};

export default NotFound;
