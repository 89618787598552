import React from "react";
import PropTypes from "prop-types";

const NoLayout = props => <React.Fragment>{props.children}</React.Fragment>;

NoLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default NoLayout;
