import React, { Component } from "react";
import DragContainer from "../DragAndDrop/DragContainer";
import { arrHalfSplit } from "../../utils/arrUtils";
import uuid from "uuid";
import PropTypes from "prop-types";

class TwoColumnList extends Component {
  col1 = arrHalfSplit(this.props.list, 1);
  col2 = arrHalfSplit(this.props.list, 2);
  resizeTimer;

  state = { mobile: false };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { list } = this.props;
    let update;

    if (this.state.mobile !== nextState.mobile) {
      return true;
    }

    if (nextProps.list.length !== list.length) {
      this.col1 = arrHalfSplit(nextProps.list, 1);
      this.col2 = arrHalfSplit(nextProps.list, 2);
      return true;
    }

    for (let i = 0; i < list.length; i++) {
      if (list[i].id !== nextProps.list[i].id) {
        update = true;
        break;
      }
    }

    if (update) {
      this.col1 = arrHalfSplit(nextProps.list, 1);
      this.col2 = arrHalfSplit(nextProps.list, 2);
      return true;
    } else {
      return false;
    }
  }

  handleResize = () => {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      if (window.innerWidth <= 450) {
        this.setState({ mobile: true });
      } else {
        this.setState({ mobile: false });
      }
    }, 250);
  };

  render() {
    if (this.state.mobile) {
      return (
        <DragContainer
          key={uuid()}
          controlSearchUpdate={this.props.controlSearchUpdate}
          dragInto={this.props.bottomGrid}
          labelKey="spelling"
          list={this.props.list}
          direction="column"
        />
      );
    }
    return (
      <div className="list__container">
        <div className="list__inner-container">
          <DragContainer
            key={uuid()}
            controlSearchUpdate={this.props.controlSearchUpdate}
            dragInto={this.props.bottomGrid}
            labelKey="spelling"
            list={this.col1}
            direction="column"
          />
        </div>

        <div className="list__inner-container">
          <DragContainer
            key={uuid()}
            controlSearchUpdate={this.props.controlSearchUpdate}
            dragInto={this.props.bottomGrid}
            labelKey="spelling"
            list={this.col2}
            direction="column"
          />
        </div>
      </div>
    );
  }
}

TwoColumnList.defaultProps = {
  list: []
};

TwoColumnList.propTypes = {
  controlSearchUpdate: PropTypes.func.isRequired,
  bottomGrid: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired
};

export default TwoColumnList;
