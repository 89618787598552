import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";
import SubmittedLogo from "../assets/images/gc_graphic.gif";

Modal.setAppElement("#root");

class Submission extends Component {
  state = {
    showModal: true,
    showConfirmation: false,
  };

  handleSubmit = () => {
    const positionDescriptions = [];
    const userPositions = [];
    const sentence = this.props.userInput;
    const positions = this.props.positions;
    const sentenceValues = Object.values(sentence);
    let objLength = Object.keys(positions).length;
    let sentenceObjectLength = Object.keys(sentence).length;

    for (let i = 0; i < objLength; i++) {
      positionDescriptions.push(positions[i].description);
    }

    for (let i = 0; i < sentenceObjectLength; i++) {
      userPositions.push(sentenceValues[i].text.toString());
    }

    const submissionData = {
      userInput: userPositions,
      assignmentName: this.props.assignmentName,
      courseName: this.props.courseName,
      moduleName: this.props.moduleName,
      positions: positionDescriptions,
      passage: this.props.passage,
    };

    // ***commented out for testing***
    axios({
      method: "POST",
      url: "https://grammarcruncher.languages.ufl.edu/submission/",
      data: submissionData,
    }).then((response) => {
      if (response.data.status === "success") {
        console.log("Message Sent.");
        this.resetForm();
      } else if (response.data.status === "fail") {
        console.log("Message failed to send.");
      }
    });

    this.handleClose();
    this.showConfirmation();
  };

  handleClose = () => {
    this.props.updateSubmit();
  };

  showConfirmation = () => {
    this.setState({ showConfirmation: true });
  };

  hideConfirmation = () => {
    this.setState({ showConfirmation: false });
  };

  render() {
    let assignmentURL = `/assignments?c=${this.props.courseID}&s=${this.props.sectionID}`;
    const positionDescriptions = [];
    let userPositions = [];
    const sentence = this.props.userInput;
    const positions = this.props.positions;
    let sentenceValues = Object.values(sentence);

    let objLength = Object.keys(positions).length;
    let sentenceObjectLength = Object.keys(sentence).length;

    for (let i = 0; i < objLength; i++) {
      positionDescriptions.push(positions[i].description);
    }

    for (let i = 0; i < sentenceObjectLength; i++) {
      if (sentenceValues[i].text.length > 0) {
        userPositions.push(
          "Position " + [i + 1] + ": " + sentenceValues[i].text.join(" ")
        );
      }
    }

    const shouldSubmit = this.props.submit;
    if (shouldSubmit === true) {
      return (
        <div>
          <Modal
            isOpen={this.state.showModal}
            onRequestClose={this.handleClose}
          >
            <div>
              <h2 className="bold--lg mb-5">Your Assignment</h2>
              <div>{`Are you sure you want to Submit your answer?`}</div>
              <br></br>
              <div>{`Course: ${this.props.courseName}`}</div>
              <br></br>
              <div>{`Assignment: ${this.props.assignmentName}`}</div>
              <br></br>
              <div>{`Description: ${positionDescriptions}`}</div>
              <br></br>
              <div>{`Positions: ${userPositions
                .join(" ")
                .replace(" ,", ",")
                .replace(" .", ".")}`}</div>
              <br></br>
            </div>
            <button
              className="secondary-btn bold--md edit-word-btn"
              onClick={this.handleClose}
            >
              Cancel
            </button>
            <button
              className="primary-btn bold--md edit-word-btn"
              onClick={this.handleSubmit}
              style={{ marginLeft: "20px" }}
            >
              Submit
            </button>
          </Modal>
        </div>
      );
    }
    if (this.state.showConfirmation === true) {
      return (
        <div>
          <Modal
            isOpen={this.state.showModal}
            onRequestClose={this.hideConfirmation}
          >
            <div className="submission-modal">
              <h2 className="bold--lg mb-5">
                Your Assignment has been submitted!
              </h2>
              <img
                className="prost"
                src={SubmittedLogo}
                alt="Assignment Submitted"
              />
              <NavLink to={assignmentURL}>
                <button
                  className="primary-btn bold--md edit-word-btn"
                  style={{ marginLeft: "20px" }}
                >
                  OK
                </button>
              </NavLink>
            </div>
          </Modal>
        </div>
      );
    }
    return <div></div>;
  }
}

const mapStateToProps = (state) => ({
  userInput: state.assignment.sentence,
  assignmentName: state.get.assignment.name,
  courseName: state.get.assignment.course.name,
  moduleName: state.get.assignment.module.name,
  positions: state.get.assignment.position,
  passage: state.get.assignment.passage,
  courseID: state.route.courseID,
  sectionID: state.route.sectionID,
});

export default connect(mapStateToProps)(Submission);
