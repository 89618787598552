import React, { Component } from "react";
import { connect } from "react-redux";
import smoothscroll from "smoothscroll-polyfill";
import { updateDragClone } from "../../actions/pages/assignmentActions";
import { updateTrayToggle } from "../../actions/components/trayActions";
import {
  updateDroppedItems,
  updateDroppedID,
} from "../../actions/components/dragDropActions";
import { updatePhraseBuilderMultiSelect } from "../../actions/components/genericToggle";
import { updateCombinedWords } from "../../actions/components/combinedWordActions";
import { updateGenericToggle } from "../../actions/components/genericToggle";

import Muuri from "muuri";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createPlaceholder } from "../../utils/dndUtils";
import uuid from "uuid";
import PropTypes from "prop-types";

class DragContainer extends Component {
  dragContainer = React.createRef();
  container;
  grid;
  body;

  createGrid() {
    this.body = document.getElementsByTagName("body");
    this.container = document.getElementById("tray-container");
    const { updateDragClone, updateTrayToggle } = this.props;
    let initMove = false;
    smoothscroll.polyfill();

    this.grid = new Muuri(this.dragContainer.current, {
      items: ".dnd__item",
      dragEnabled: true,
      dragContainer: document.body,
      dragSortHeuristics: {
        sortInterval: 10,
      },
      dragPlaceholder: {
        enabled: true,
        duration: 400,
        easing: "ease-out",
        createElement() {
          return createPlaceholder();
        },
      },
      dragSort: () => {
        return this.props.dragInto;
      },
      dragSortPredicate: (item) => {
        const dragResult = Muuri.ItemDrag.defaultSortPredicate(item, {
          actions: "swap",
          threshold: 50,
        });

        if (dragResult) {
          if (dragResult.index === dragResult.grid._items.length - 1) {
            return false;
          } else if (dragResult.index === -1) {
            return {
              ...dragResult,
              index: dragResult.grid._items.length - 1,
            };
          } else {
            return dragResult;
          }
        }
      },
    })
      .on("dragMove", (item, event) => {
        if (updateTrayToggle) {
          updateTrayToggle(true);
          disableBodyScroll(this.body);
        }
        initMove = true;
        const containerRect = this.container.getBoundingClientRect();

        if (
          containerRect.top + 50 > event.clientY &&
          containerRect.top - 15 < event.clientY
        ) {
          const intensity = Math.floor(
            event.clientY - (containerRect.top - 15)
          );
          this.autoScroll = true;
          this.handleScroll(-10, intensity);
        } else if (
          containerRect.bottom - 50 < event.clientY &&
          containerRect.bottom + 15 > event.clientY
        ) {
          const intensity = Math.floor(
            containerRect.bottom + 15 - event.clientY
          );
          this.autoScroll = true;
          this.handleScroll(10, intensity);
        } else if (this.autoScroll) {
          this.autoScroll = false;
          this.handleScroll(null, null, true);
        }
      })
      .on("send", (data) => {
        const cloneMap = {};
        cloneMap[data.item._id] = {
          item: data.item,
          grid: data.fromGrid,
          index: data.fromIndex,
        };
        updateDragClone(cloneMap);
      })
      .on("dragEnd", (item, e) => {
        let currentDroppedId = item._element.attributes.getNamedItem("data-id");
        this.props.updateDroppedID(currentDroppedId);

        let innerWordText = item._element.innerText;
        this.props.updateDroppedItems(innerWordText);
        this.props.updateGenericToggle();
        if (!initMove) {
          if (
            e.distance < 10 &&
            e.deltaTime > 500 &&
            (e.srcEvent.pointerType === "touch" ||
              e.srcEvent.type === "touchend")
          ) {
            this.props.updatePhraseBuilderMultiSelect();
            this.props.updateCombinedWords(innerWordText);
          } else if (
            e.distance < 10 &&
            !this.props.phraseBuilderMultiSelect &&
            !e.srcEvent.shiftKey
          ) {
            // workaround to capture click - if the item didn't move, search the dictionary
            const { controlSearchUpdate } = this.props;
            if (controlSearchUpdate) {
              // 'data-redirect' is used to replace the dictionary lookup target
              const redirect = item._element.getAttribute("data-redirect");
              if (redirect === "false") {
                controlSearchUpdate(innerWordText);
              } else {
                controlSearchUpdate(`${innerWordText}[${redirect}]`);
              }
            }
          }
        }
        setTimeout(() => {
          updateTrayToggle(false);
          enableBodyScroll(this.body);
        }, 520);

        initMove = false;
        this.autoScroll = false;
        this.handleScroll(null, null, true);
      });
  }

  destroyGrid() {
    this.grid.destroy();
  }

  componentDidMount() {
    this.createGrid();
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  componentDidUpdate() {
    this.createGrid();
  }

  handleScroll = (dir, time, clear = false) => {
    if (clear) {
      return clearInterval(this.handleScroll.instance);
    } else {
      if (this.handleScroll.instance) {
        clearInterval(this.handleScroll.instance);
      }

      this.handleScroll.instance = setInterval(() => {
        this.container.scrollBy(0, dir);
      }, time);
    }
  };

  handleKeyPress = (e) => {
    if (e.which === 13 && e.altKey) {
      const { controlSearchUpdate } = this.props;
      controlSearchUpdate(e.target.innerText);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.genericToggle === nextProps.genericToggle) {
      return false;
    } else {
      return true;
    }
  }

  handleMouseDownEvent = (event) => {
    if (
      event !== undefined &&
      !window.matchMedia("(max-width: 1024px)").matches
    ) {
      let innerWordText = event.target.innerText
        ? event.target.innerText + " "
        : "";
      if (event.shiftKey) {
        this.props.updateCombinedWords(innerWordText);
      } else if (this.props.phraseBuilderMultiSelect && event.target) {
        this.props.updateCombinedWords(innerWordText);
      }
    }
  };

  getItemInfo = (event) => {
    if (
      this.props.phraseBuilderMultiSelect &&
      event.targetTouches.length >= 1
    ) {
      let innerWordText = event.target.innerText
        ? event.target.innerText + " "
        : "";
      this.props.updateCombinedWords(innerWordText);
    }
  };

  wordParser = (selectedWord) => {
    let redirect = false;
    let displayWord = "";
    let newString = "";

    selectedWord = selectedWord.replace(/_/g, " ");

    for (let i = 0; i < selectedWord.length; i++) {
      if (redirect === true && selectedWord[i] !== "]") {
        newString += selectedWord[i];
        continue;
      }

      if (selectedWord[i] === "[") {
        redirect = true;
        continue;
      } else if (selectedWord[i] !== "]") {
        displayWord += selectedWord[i];
      }
    }

    return {
      word: displayWord,
      redirect: redirect ? newString : false,
    };
  };

  render() {
    const { direction, labelKey, list } = this.props;
    const classes = `dnd__draggable-item `;

    return (
      <>
        <div className={`dnd__${direction}`}>
          <div ref={this.dragContainer} className="dnd__drag-grid">
            {list.map((item, index) => {
              let word, redirect;
              if (typeof item === "string") {
                const wordInfo = this.wordParser(item);
                word = wordInfo.word;
                redirect = wordInfo.redirect;
              } else {
                word = item[labelKey];
                redirect = false;
              }
              return (
                <article
                  className="dnd__item"
                  data-id={index}
                  data-type="draggable"
                  data-redirect={redirect}
                  key={uuid()}
                  tabIndex="0"
                  onKeyUp={this.handleKeyPress}
                  onMouseDown={this.handleMouseDownEvent}
                >
                  <div className={classes} onTouchStart={this.getItemInfo}>
                    <div className="dnd__draggable-container">
                      <FontAwesomeIcon
                        className="dnd__draggable-icon"
                        icon="grip-vertical"
                      />
                      <div id="nested">
                        <span id="wordSpan">{word}</span>
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

DragContainer.defaultProps = {
  direction: "row",
  dragInto: [],
};

DragContainer.propTypes = {
  controlSearchUpdate: PropTypes.func,
  direction: PropTypes.oneOf(["row", "column"]),
  dragInto: PropTypes.array,
  labelKey: PropTypes.string,
  list: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  combinedWords: state.combinedWords.combinedWordsList,
  genericToggle: state.genericToggle.genericToggle,
  phraseBuilderMultiSelect: state.genericToggle.phraseBuilderMultiSelect,
});

const mapDispatchToProps = (dispatch) => ({
  updateTrayToggle: (value) => dispatch(updateTrayToggle(value)),
  updateDragClone: (value) => dispatch(updateDragClone(value)),
  updateDroppedItems: (value) => dispatch(updateDroppedItems(value)),
  updateCombinedWords: (value) => dispatch(updateCombinedWords(value)),
  updateGenericToggle: (value) => dispatch(updateGenericToggle(value)),
  updateDroppedID: (value) => dispatch(updateDroppedID(value)),
  updatePhraseBuilderMultiSelect: () =>
    dispatch(updatePhraseBuilderMultiSelect()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DragContainer);
