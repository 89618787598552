import React, { Component } from "react";

import Muuri from "muuri";
import { connect } from "react-redux";
import {
  updateDragClone,
  updateSentence,
} from "../../actions/pages/assignmentActions";
import { updateDroppedItems } from "../../actions/components/dragDropActions";
import { updateGenericToggle } from "../../actions/components/genericToggle";

import {
  addNewDraggable,
  cloneItem,
  createPlaceholder,
  grabItemIndex,
} from "../../utils/dndUtils";
import { strToArray } from "../../utils/arrUtils";
import EditModal from "./EditModal";
import accessibilityPlugin from "./accessibilityPlugin";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DropContainer extends Component {
  state = {
    item: null,
    text: undefined,
    modalIsOpen: false,
    title: "",
    desc: "",
    positionIndex: null,
    btnText: "",
    forwardTab: true,
    toggleDesc: false,
  };

  dropContainer = React.createRef();
  grid;
  autoScroll = false;
  container;

  checkForDraggedItem = (droppedItem) => {
    let draggableTiles = document.querySelectorAll(
      "div.dnd__drag-grid .dnd__item"
    );
    draggableTiles.forEach((tile) => {
      let containerIDs = tile.attributes.getNamedItem("data-id");
      if (
        this.props.droppedID !== null &&
        containerIDs !== null &&
        containerIDs.value === this.props.droppedID.value &&
        tile.innerText.includes(droppedItem) &&
        tile.parentElement.parentElement.parentElement.className.search(
          "datalist__item"
        ) < 0
      ) {
        tile.classList += " highlightedItem";
      }
    });
  };

  componentDidMount() {
    const { gridInstance, punctuation } = this.props;
    const body = document.getElementsByTagName("body")[0];
    body.addEventListener("keyup", this.handleTabFocus);
    this.container = document.getElementById("tray-container");

    this.grid = new Muuri(this.dropContainer.current, {
      items: ".dnd__item",
      dragEnabled: true,
      dragContainer: document.body,
      dragSortHeuristics: {
        sortInterval: 10,
      },
      dragPlaceholder: {
        enabled: true,
        duration: 400,
        easing: "ease-out",
        createElement() {
          return createPlaceholder();
        },
      },
      dragSort: () => [
        ...this.props.bottomGrid,
        this.props.deleteGrid,
        this.grid,
      ],
      dragStartPredicate: (item, event) => {
        if (item._element.hasAttribute("data-type")) {
          // 'position' indicates punctuation and punctuation shouldn't move
          if (item._element.getAttribute("data-type") === "position") {
            return false;
          }
        }
        return true;
      },
      dragSortPredicate: (item) => {
        const dragResult = Muuri.ItemDrag.defaultSortPredicate(item);

        if (dragResult) {
          if (dragResult.index === dragResult.grid._items.length - 1) {
            return false;
          } else if (dragResult.index === -1) {
            return {
              ...dragResult,
              index: dragResult.grid._items.length - 1,
            };
          } else {
            return dragResult;
          }
        }
      },
    })
      .on("dragEnd", (item, e) => {
        if (e.distance < 10) {
          this.setState({
            text: item._element.innerText,
            title: "Edit word:",
            item: item,
            modalIsOpen: true,
            btnText: "Update",
          });
        }

        if (this.grid._id !== item._gridId) {
          this.grid.synchronize();

          const getItems = this.grid.getItems();
          let items = [];
          getItems.map((item) => {
            if (item._element.innerText.length) {
              items = [...items, item._element.innerText];
            }
            return undefined;
          });

          this.props.updateSentence(this.props.num, items, this.grid._id);
        }

        this.autoScroll = false;
        this.handleScroll(null, null, true);
      })
      .on("dragReleaseEnd", () => {
        this.grid.synchronize();

        const getItems = this.grid.getItems();
        let items = [];
        getItems.map((item) => {
          if (item._element.innerText.length) {
            items = [...items, item._element.innerText];
          }
          return undefined;
        });

        this.props.updateSentence(this.props.num, items, this.grid._id);
      })
      .on("dragReleaseStart", (item) => {
        const cloneData = this.props.dragClone[item._id];
        if (cloneData) {
          const clone = cloneItem(cloneData.item.getElement());
          cloneData.grid.add(clone, { index: cloneData.index });
          cloneData.grid.show(clone);
          cloneData.grid.synchronize();
          this.props.updateDragClone({});
        }
        this.checkForDraggedItem(this.props.droppedItems);
      })
      .on("dragMove", (item, event) => {
        const containerRect = this.container.getBoundingClientRect();

        if (
          containerRect.top + 50 > event.clientY &&
          containerRect.top - 15 < event.clientY
        ) {
          const intensity = Math.floor(
            event.clientY - (containerRect.top - 15)
          );
          this.autoScroll = true;
          this.handleScroll(-10, intensity);
        } else if (
          containerRect.bottom - 50 < event.clientY &&
          containerRect.bottom + 15 > event.clientY
        ) {
          const intensity = Math.floor(
            containerRect.bottom + 15 - event.clientY
          );
          this.autoScroll = true;
          this.handleScroll(10, intensity);
        } else if (this.autoScroll) {
          this.autoScroll = false;
          this.handleScroll(null, null, true);
        }
      });

    const { updateSentence, num } = this.props;
    let arr = [];
    if (punctuation) arr = [punctuation];
    updateSentence(num, arr, this.grid._id);

    accessibilityPlugin(this.grid, this.dropContainer.current);
    if (gridInstance) gridInstance(this.grid);
  }

  componentWillUnmount() {
    accessibilityPlugin(undefined, undefined, true);
  }

  componentDidUpdate() {}

  handleTabFocus = (e) => {
    const key = e.which || e.keyCode;
    const { forwardTab } = this.state;

    // tab traversing backward but forward tab is true
    if (key === 9 && e.shiftKey === true && forwardTab === true) {
      return this.setState({ forwardTab: false });
    }
    // tab traversing forward but forwardTab is false
    else if (key === 9 && e.shiftKey === false && forwardTab === false) {
      return this.setState({ forwardTab: true });
    }

    return;
  };

  handleScroll = (dir, time, clear = false) => {
    if (clear) {
      return clearInterval(this.handleScroll.instance);
    } else {
      if (this.handleScroll.instance) {
        clearInterval(this.handleScroll.instance);
      }

      this.handleScroll.instance = setInterval(() => {
        this.container.scrollBy(0, dir);
      }, time);
    }
  };

  controlUpdate = () => {
    const index = Array.isArray(this.state.item)
      ? 1
      : grabItemIndex(this.grid._items, this.state.item._id);

    this.grid.remove(this.state.item, { removeElements: true });

    const arr = strToArray(this.state.text);
    for (let i = 0; i < arr.length; i++) {
      this.grid.add(addNewDraggable(arr[i]), { index: index + i - 1 });
    }

    const getItems = this.grid.getItems();
    let items = [];
    getItems.map((item) => {
      if (item._element.innerText.length) {
        items = [...items, item._element.innerText];
      }
      return undefined;
    });

    this.grid.synchronize();
    this.props.updateSentence(this.props.num, items, this.grid._id);
  };

  handleEditPosition = () => {
    this.grid.synchronize();
    const items = this.grid._items;
    let itemsArr = [];
    let strArr = [];

    for (let i = 0; i < items.length - 1; i++) {
      const type = this.grid._items[i]._element.getAttribute("data-type");

      if (type === "draggable") {
        itemsArr = [...itemsArr, this.grid._items[i]];
        strArr = [...strArr, this.grid._items[i]._element.innerText];
      }
    }

    return this.setState({
      text: strArr.join(" "),
      item: itemsArr,
      title: `Edit position ${this.props.num}`,
      desc: this.props.desc,
      modalIsOpen: true,
      btnText: strArr.length === 0 ? "Add" : "Update",
    });
  };

  handleDeleteSentence = () => {
    this.props.updateDroppedItems("reset");
    this.props.updateGenericToggle();

    const { sentence, updateSentence } = this.props;
    const arr = [];

    for (let item in sentence) {
      if (sentence[item].id === this.grid._id && this.grid._items.length > 1) {
        for (let i = 0; i < this.grid._items.length - 1; i++) {
          arr.push(this.grid._items[i]);
        }

        updateSentence(
          item,
          [this.grid._items[this.grid._items.length - 1]._element.innerText],
          this.grid._id
        );
        this.grid.remove(arr, { removeElements: true });
      }
    }
  };

  handleFocusRoute = () => {
    const firstItem = this.grid._items[1]._element.attributes["data-type"]
      .value;
    if (firstItem === "position") {
      this.grid.add(createPlaceholder(), {
        index: 1,
        layout: "instant",
      });
      this.grid.synchronize();
      this.grid._items[1]._element.focus();
    } else {
      this.grid._items[1]._element.focus();
    }

    this.props.bottomGrid.forEach((grid) => {
      if (grid._id !== this.grid._id) {
        grid._element.childNodes.forEach((child) => {
          if (child.getAttribute("data-type") === "placeholder") {
            grid.remove(child, { removeElements: true });
          }
        });
      }
    });
  };

  render() {
    const { punctuation } = this.props;
    const gridID = this.grid !== undefined ? this.grid._id : "";

    return (
      <>
        {this.props.index !== 0 && <hr className="position__hr" />}
        <div className="position__container--header">
          <div>
            <div className="bold--lg position__label">
              {`Position ${this.props.num}`}
              <button
                className="position__toggle-btn"
                onClick={() =>
                  this.setState({ toggleDesc: !this.state.toggleDesc })
                }
              >
                {`${this.state.toggleDesc ? "Hide" : "Show"} Description`}
              </button>
            </div>
            {this.state.toggleDesc && (
              <div className="position__desc">{this.props.desc}</div>
            )}
          </div>
          <div className="position__btn-container">
            <button
              className="filter-btn bold--sm mr-15 position__btn"
              onClick={this.handleEditPosition}
            >
              <FontAwesomeIcon className="mr-10" icon="edit" />
              Edit
            </button>
            <button
              className="filter-btn--applied bold--sm"
              onClick={this.handleDeleteSentence}
            >
              <FontAwesomeIcon className="mr-10" icon="times-circle" />
              Clear
            </button>
          </div>
        </div>
        <div className="position__container">
          <div className="position__container--drop">
            <div className="dnd__drop-container" data-id={gridID}>
              <section ref={this.dropContainer} className="dnd__drop-target">
                <article
                  onFocus={this.handleFocusRoute}
                  tabIndex={this.state.forwardTab ? "0" : "-1"}
                  className="dnd__item"
                  data-type="init-placeholder"
                >
                  <div className="dnd__init-placeholder" />
                </article>
                {punctuation ? (
                  <article className="dnd__item" data-type="position">
                    <div className="dnd__item-content">
                      <div className="dnd__position">{punctuation}</div>
                    </div>
                  </article>
                ) : (
                  <article className="dnd__item" data-type="position">
                    <div className="dnd__item-content" />
                  </article>
                )}
              </section>
              {this.state.modalIsOpen && (
                <EditModal
                  text={this.state.text}
                  updateText={(value) => this.setState({ text: value })}
                  controlUpdate={this.controlUpdate}
                  title={this.state.title}
                  btnText={this.state.btnText}
                  desc={this.state.desc}
                  closeModal={() =>
                    this.setState({
                      text: undefined,
                      title: "",
                      desc: "",
                      modalIsOpen: false,
                      item: null,
                      positionIndex: null,
                      btnIndex: "",
                    })
                  }
                  openModal={this.state.modalIsOpen}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

DropContainer.propTypes = {
  desc: PropTypes.string.isRequired,
  gridInstance: PropTypes.func.isRequired,
  punctuation: PropTypes.string,
  num: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  dragClone: state.assignment.dragClone,
  bottomGrid: state.tray.bottomGrid,
  deleteGrid: state.tray.deleteGrid,
  sentence: state.assignment.sentence,
  droppedItems: state.dropContainer.droppedItems,
  genericToggle: state.genericToggle.genericToggle,
  droppedID: state.dropContainer.droppedID,
});

const mapDispatchToProps = (dispatch) => ({
  updateDroppedItems: (value) => dispatch(updateDroppedItems(value)),
  updateDragClone: (value) => dispatch(updateDragClone(value)),
  updateSentence: (position, arr, id) =>
    dispatch(updateSentence(position, arr, id)),
  updateGenericToggle: (value) => dispatch(updateGenericToggle(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DropContainer);
