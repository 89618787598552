import {
  activeElementInfo,
  cloneItem,
  createPlaceholder,
  grabNextItem,
  grabPlaceholder,
  grabPlaceholderIndex,
  isEmptyObj
} from "../../utils/dndUtils";
import store from "../../store";
import { updateTrayToggle } from "../../actions/components/trayActions";

export default function(grid, container, unmount = false) {
  let savedPlaceholder;
  const plugin = {
    routeListener: (e) => {
      switch (e.which || e.keyCode) {
        case 39:
          return plugin.keyboardNav(e, "right");
        case 37:
          return plugin.keyboardNav(e, "left");
        case 13:
          if (!e.altKey) {
            return plugin.addItem(e);
          }
          break;
        case 8:
        case 46:
          return plugin.deleteItem(e);
        default:
          return null;
      }
    },
    keyboardNav: (e, direction) => {
      // grab index and element info of current focused element
      const info = activeElementInfo(container, direction);
      if (isEmptyObj(info)) return null;

      if (info.type === "placeholder") {
        // grab item to focus to
        const newItem = grabNextItem(container, info.item, direction);

        // cancel if next item is a position and request is to move right
        const isPosition = newItem.getAttribute("data-type") === "position";
        if (isPosition && direction === "right") return null;

        // cancel if at front and moving left
        if (info.index === 1 && direction === "left") return null;

        // delete old placeholder
        const oldPlaceholder = grabPlaceholder(container);
        grid.remove(oldPlaceholder, { removeElements: true });

        // synchronize
        grid.synchronize();

        // set focus to new item
        newItem.focus();

        // If active element is draggable or a position
      } else {
        // Remove any old placeholders that may still exist
        const oldPlaceholder = grabPlaceholder(container);
        grid.remove(oldPlaceholder, { removeElements: true });

        // rerun activeElementInfo to get update index
        const newInfo = activeElementInfo(container, direction);

        // add new placeholder
        switch (direction) {
          case "left":
            grid.add(createPlaceholder(), {
              index: newInfo.index,
              layout: "instant"
            });
            break;
          case "right":
            grid.add(createPlaceholder(), {
              index: newInfo.index + 1,
              layout: "instant"
            });
            break;
          default:
            return null;
        }

        // Synchronize the item elements to match the order of the items in the DOM
        grid.synchronize();

        // Grab next item
        const newItem = grabNextItem(container, newInfo.item, direction);

        // set focus to that item
        newItem.focus();
      }
    },
    addItem: (e) => {
      //check if active element is a placeholder
      const item = document.activeElement;
      const parentItem = item.parentElement.parentElement;

      if (parentItem === null) return;

      //if active element is a placeholder and grid match put focus on top drop container
      if (
        parentItem.hasAttribute("data-id") &&
        Number(parentItem.getAttribute("data-id")) === grid._id &&
        item.hasAttribute("data-type") &&
        item.getAttribute("data-type") === "placeholder"
      ) {
        savedPlaceholder = item.parentElement.parentElement.attributes[1].value;

        store.dispatch(updateTrayToggle(false));
        document.getElementsByClassName("assignment-list__btn")[0].focus();
      }

      if (
        item.hasAttribute("data-type") &&
        item.getAttribute("data-type") === "draggable"
      ) {
        // grab class list from parent
        const itemClassList = Array.prototype.slice.call(
          item.parentElement.classList
        );

        if (itemClassList.includes("dnd__drag-grid")) {
          // grab index of placeholder
          const placeholderIndex = grabPlaceholderIndex(container, item);
          if (savedPlaceholder === undefined) return null;

          // add item to placeholder index
          const clone = cloneItem(item);
          grid.add(clone, { index: placeholderIndex });
          grid.show(clone);

          // sync
          grid.synchronize();

          // remove placeholder
          const oldPlaceholder = grabPlaceholder(container);
          grid.remove(oldPlaceholder, { removeElements: true });
          savedPlaceholder = undefined;

          store.dispatch(updateTrayToggle(true));

          // put focus on item
          clone.focus();
        }
      }
    },
    deleteItem: (e) => {
      const item = document.activeElement;
      const newItem = grabNextItem(container, item, "right");

      if (item.hasAttribute("data-type")) {
        if (item.getAttribute("data-type") !== "draggable") return null;

        const parentContainer = item.parentElement;
        if (parentContainer !== container) return null;

        grid.remove(item, { removeElements: true });

        newItem.focus();
      }
    }
  };

  document.addEventListener("keydown", plugin.routeListener);
  if (unmount) document.removeEventListener("keydown", plugin.routeListener);
}
