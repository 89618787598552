import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { filterList } from "../../utils/autoCompleteUtils";
import PropTypes from "prop-types";

const Search = props => {
  const {
    controlClear,
    controlUpdate,
    lang,
    list,
    keyName,
    placeholder,
    searchValue
  } = props;

  const [suggestions, setSuggestions] = useState([]);

  const config = {
    getSuggestions: value => filterList(value, list, keyName, true, true),
    handleClearSuggestions: () => setSuggestions([]),
    handleFetchSuggestion: ({ value }) =>
      setSuggestions(config.getSuggestions(value, list)),
    handleRenderSuggestion: suggestion => (
      <div lang={lang}>{suggestion[keyName]}</div>
    ),
    handleSuggestionValue: suggestion => suggestion[keyName]
  };

  const inputProps = {
    value: searchValue,
    onChange: (event, { newValue }) => controlUpdate(newValue),
    type: "text",
    placeholder: placeholder,
    lang
  };

  return (
    <React.Fragment>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={config.handleFetchSuggestion}
        onSuggestionsClearRequested={config.handleClearSuggestions}
        getSuggestionValue={config.handleSuggestionValue}
        renderSuggestion={config.handleRenderSuggestion}
        inputProps={inputProps}
        focusInputOnSuggestionClick={false}
      />
      {searchValue && (
        <button
          aria-label="clear selected option"
          className="auto-complete__clear-btn"
          onClick={controlClear}
        >
          <FontAwesomeIcon className="color-black-60" icon="times-circle" />
        </button>
      )}
    </React.Fragment>
  );
};

Search.defaultProps = {
  lang: "en"
};

Search.propTypes = {
  controlClear: PropTypes.func.isRequired,
  controlUpdate: PropTypes.func.isRequired,
  keyName: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired
};

export default Search;
