import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateSearchValue,
  updateToggleFilter
} from "../../actions/components/dictionaryActions";
import {
  resetSearchFilter,
  updateFilteredList
} from "../../actions/components/searchFilterActions";
import SearchFilter from "../SearchFilter";
import Pagination from "../Pagination";
import PropTypes from "prop-types";
import TwoColumnList from "../TwoColumnList";

class DictionaryView extends Component {
  state = { pageOfItems: [], toggleList: true };

  componentWillUnmount() {
    const { resetSearchFilter, updateFilteredList, words } = this.props;
    resetSearchFilter();
    updateFilteredList(words);
  }

  handleSearchMatch = (obj, bool) => {
    if (bool) {
      const { controlSearchUpdate, searchValue } = this.props;
      return controlSearchUpdate(searchValue);
    }
  };

  render() {
    const {
      controlSearchUpdate,
      words,
      filteredList,
      searchValue,
      toggleFilter,
      updateSearchValue,
      updateToggleFilter
    } = this.props;

    return (
      <div>
        <SearchFilter
          alphabeticalKey="spelling"
          keyName="spelling"
          lang="de"
          list={words}
          placeholder="Search dictionary for..."
          posKey="pos"
          selectedFilters={["AlphabeticalFilter", "POSFilter"]}
          searchValue={searchValue}
          toggleFilter={toggleFilter}
          updateMatchedObj={this.handleSearchMatch}
          updateSearchValue={value => updateSearchValue(value)}
          updateToggleFilter={value => updateToggleFilter(value)}
        />

        <div className="mt-25 bold--md">
          {`
            ${filteredList.length} 
            ${filteredList.length === 1 ? "Result found" : "Results found"}
          `}
        </div>

        <TwoColumnList
          list={this.state.pageOfItems}
          controlSearchUpdate={controlSearchUpdate}
          bottomGrid={this.props.bottomGrid}
        />

        <Pagination
          list={filteredList}
          handleResult={pageOfItems => this.setState({ pageOfItems })}
        />
      </div>
    );
  }
}

DictionaryView.propTypes = {
  controlSearchUpdate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  words: state.get.allWords,
  filteredList: state.searchFilter.filteredList,
  searchValue: state.dictionary.searchValue,
  toggleFilter: state.dictionary.toggleFilter
});

const mapDispatchToProps = dispatch => ({
  resetSearchFilter: () => dispatch(resetSearchFilter()),
  updateFilteredList: value => dispatch(updateFilteredList(value)),
  updateSearchValue: value => dispatch(updateSearchValue(value)),
  updateToggleFilter: value => dispatch(updateToggleFilter(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DictionaryView);
