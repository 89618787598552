import React, { Component } from "react";
import { connect } from "react-redux";
import smoothscroll from "smoothscroll-polyfill";
import { updateDragClone } from "../actions/pages/assignmentActions";
import { updateTrayToggle } from "../actions/components/trayActions";
import {
  updateDroppedItems,
  updateDroppedID,
} from "../actions/components/dragDropActions";
import { updateCombinedWords } from "../actions/components/combinedWordActions";
import {
  updatePhraseBuilderToggle,
  updatePhraseBuilderMultiSelect,
} from "../actions/components/genericToggle";

import Muuri from "muuri";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createPlaceholder } from "../utils/dndUtils";
import uuid from "uuid";
import { faUndo } from "@fortawesome/free-solid-svg-icons";

class PhraseBuilder extends Component {
  dragContainer = React.createRef();

  container;
  grid;
  body;

  createGrid() {
    this.body = document.getElementsByTagName("body");
    this.container = document.getElementById("tray-container");
    const { updateDragClone, updateTrayToggle } = this.props;
    let initMove = true;
    smoothscroll.polyfill();

    this.grid = new Muuri(this.dragContainer.current, {
      items: ".dnd__item",
      dragEnabled: true,
      dragContainer: document.body,
      dragSortHeuristics: {
        sortInterval: 10,
      },
      dragPlaceholder: {
        enabled: true,
        duration: 400,
        easing: "ease-out",
        createElement() {
          return createPlaceholder();
        },
      },
      dragSort: () => {
        // draginto is a prop defined when this component is called, it determines which
        // grids this grid s items can be dragged into
        return this.props.dragInto;
      },
      dragSortPredicate: (item) => {
        const dragResult = Muuri.ItemDrag.defaultSortPredicate(item, {
          actions: "swap",
          threshold: 50,
        });

        if (dragResult) {
          if (dragResult.index === dragResult.grid._items.length - 1) {
            return false;
          } else if (dragResult.index === -1) {
            return {
              ...dragResult,
              index: dragResult.grid._items.length - 1,
            };
          } else {
            return dragResult;
          }
        }
      },
    })
      .on("dragMove", (item, event) => {
        if (event.distance >= 10 && initMove) {
          if (updateTrayToggle) {
            updateTrayToggle(true);
            disableBodyScroll(this.body);
          }
          initMove = false;
        }
        if (!initMove) {
          const containerRect = this.container.getBoundingClientRect();

          if (
            containerRect.top + 50 > event.clientY &&
            containerRect.top - 15 < event.clientY
          ) {
            const intensity = Math.floor(
              event.clientY - (containerRect.top - 15)
            );
            this.autoScroll = true;
            this.handleScroll(-10, intensity);
          } else if (
            containerRect.bottom - 50 < event.clientY &&
            containerRect.bottom + 15 > event.clientY
          ) {
            const intensity = Math.floor(
              containerRect.bottom + 15 - event.clientY
            );
            this.autoScroll = true;
            this.handleScroll(10, intensity);
          } else if (this.autoScroll) {
            this.autoScroll = false;
            this.handleScroll(null, null, true);
          }
        }
      })
      .on("send", (data) => {
        const cloneMap = {};
        cloneMap[data.item._id] = {
          item: data.item,
          grid: data.fromGrid,
          index: data.fromIndex,
        };
        updateDragClone(cloneMap);
      })
      .on("dragEnd", (item, e) => {
        let currentDroppedId = item._element.attributes.getNamedItem("data-id");
        this.props.updateDroppedID(currentDroppedId);
        this.props.updatePhraseBuilderToggle();
        this.props.updateCombinedWords("reset");
        this.props.updatePhraseBuilderMultiSelect(false);

        if (e.distance < 10) {
          // workaround to capture click - if the item didn't move, search the dictionary
          const { controlSearchUpdate } = this.props;
          if (controlSearchUpdate) {
            // 'data-redirect' is used to replace the dictionary lookup target
            const redirect = item._element.getAttribute("data-redirect");
            if (redirect === "false") {
              controlSearchUpdate(item._element.innerText);
            } else {
              controlSearchUpdate(`${item._element.innerText}[${redirect}]`);
            }
          }
        } else {
          setTimeout(() => {
            updateTrayToggle(false);
            enableBodyScroll(this.body);
          }, 520);
        }
        initMove = true;
        this.autoScroll = false;
        this.handleScroll(null, null, true);
      });
  }

  destroyGrid() {
    this.grid.destroy();
  }

  componentDidMount() {
    this.createGrid();
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  componentDidUpdate() {
    this.createGrid();
  }

  handleScroll = (dir, time, clear = false) => {
    if (clear) {
      return clearInterval(this.handleScroll.instance);
    } else {
      if (this.handleScroll.instance) {
        clearInterval(this.handleScroll.instance);
      }

      this.handleScroll.instance = setInterval(() => {
        this.container.scrollBy(0, dir);
      }, time);
    }
  };

  handleKeyPress = (e) => {
    if (e.which === 13 && e.altKey) {
      const { controlSearchUpdate } = this.props;
      controlSearchUpdate(e.target.innerText);
    }
  };

  handlePhraseBuilderUndo = () => {
    this.props.updateCombinedWords("undo");
  };

  handlePhraseBuilderClear = () => {
    this.props.updateCombinedWords("reset");
  };

  wordParser = (selectedWord) => {
    let redirect = false;
    let displayWord = "";
    let newString = "";

    selectedWord = selectedWord.replace(/_/g, " ");

    for (let i = 0; i < selectedWord.length; i++) {
      if (redirect === true && selectedWord[i] !== "]") {
        newString += selectedWord[i];
        continue;
      }

      if (selectedWord[i] === "[") {
        redirect = true;
        continue;
      } else if (selectedWord[i] !== "]") {
        displayWord += selectedWord[i];
      }
    }

    return {
      word: displayWord,
      redirect: redirect ? newString : false,
    };
  };

  render() {
    const { direction } = this.props;

    return (
      <>
        <div className='phraseContainer'>
          <div className='phraseHeader'>
            <h2 className='phraseBuilderTitle'>Build Your Phrase</h2>
            <div className='phraseButtonGroup'>
              <button
                className='phraseUndoButton bold--sm'
                onClick={this.handlePhraseBuilderUndo}>
                <FontAwesomeIcon className='mr-10' icon={faUndo} />
                Undo
              </button>
              <button
                className='phraseClearButton bold--sm'
                onClick={this.handlePhraseBuilderClear}>
                <FontAwesomeIcon className='mr-10' icon='times-circle' />
                Clear
              </button>
            </div>
          </div>
          <div className={`dnd__${direction}`}>
            <div className='dnd__drop-container'>
              <div ref={this.dragContainer} className='dnd__drag-grid'>
                <article
                  className='dnd__item'
                  data-type='draggable'
                  data-redirect={false}
                  key={uuid()}
                  tabIndex='0'
                  onKeyUp={this.handleKeyPress}>
                  <div className='dnd__draggable-item'>
                    <div className='dnd__draggable-container'>
                      <FontAwesomeIcon
                        className='dnd__draggable-icon'
                        icon='grip-vertical'
                      />
                      <div id='nested'>
                        <span id='wordSpan'>{this.props.combinedWords}</span>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

PhraseBuilder.defaultProps = {
  direction: "row",
  dragInto: [],
};

const mapStateToProps = (state) => ({
  combinedWords: state.combinedWords.combinedWordsList,
  genericToggle: state.genericToggle.genericToggle,
  phraseBuilderToggle: state.genericToggle.phraseBuilderToggle,
});

const mapDispatchToProps = (dispatch) => ({
  updateTrayToggle: (value) => dispatch(updateTrayToggle(value)),
  updateDragClone: (value) => dispatch(updateDragClone(value)),
  updateDroppedItems: (value) => dispatch(updateDroppedItems(value)),
  updateCombinedWords: (value) => dispatch(updateCombinedWords(value)),
  updatePhraseBuilderToggle: (value) =>
    dispatch(updatePhraseBuilderToggle(value)),
  updateDroppedID: (value) => dispatch(updateDroppedID(value)),
  updatePhraseBuilderMultiSelect: (value) =>
    dispatch(updatePhraseBuilderMultiSelect(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhraseBuilder);
