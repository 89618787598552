import { GenericActions } from "../types";
const {
  UPDATE_TOGGLE,
  UPDATE_PHRASE_BUILDER_TOGGLE,
  UPDATE_PHRASE_BUILDER_MULTI_SELECT,
} = GenericActions;

export const updateGenericToggle = (payload) => ({
  type: UPDATE_TOGGLE,
  payload,
});

export const updatePhraseBuilderToggle = (payload) => ({
  type: UPDATE_PHRASE_BUILDER_TOGGLE,
  payload,
});

export const updatePhraseBuilderMultiSelect = (payload) => ({
  type: UPDATE_PHRASE_BUILDER_MULTI_SELECT,
  payload,
});
