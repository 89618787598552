import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BrowserNotSupported = () => {
  document.title = "Browser Not Supported - Grammar Cruncher";

  return (
    <div className=" error-page not-supported__container">
      <div>
        <div className="not-supported__icon-container">
          <FontAwesomeIcon
            className="not-supported__red-alert not-supported__icon"
            icon="exclamation-triangle"
            size="6x"
          />
        </div>

        <section className="not-supported__content">
          <h1 className="not-supported__red-alert">Browser Not Supported</h1>
          <p>
            It appears that you are visiting this site from an unsupported
            browser. Though you can continue, some aspects of the application
            may not function as expected. Please see the list of supported
            browsers below.
          </p>

          <ul>
            <li>Chrome: version 57 and later</li>
            <li>Safari: version 10.1 and later</li>
            <li>Firefox: version 52 and later</li>
            <li>Edge: version 16 and later</li>
            <li>Opera: version 44 and later</li>
          </ul>

          <a
            className="secondary-btn bold--md mt-50 mr-25 mb-25 not-supported__btn"
            href="https://browser-update.org/update-browser.html"
          >
            <FontAwesomeIcon className="mr-15" icon="info-circle" />
            More Information
          </a>

          <Link
            className="negative-btn--high-contrast bold--md not-supported__btn"
            to="/admin"
            onClick={() => this.props.controlIsValid()}
          >
            <FontAwesomeIcon className="mr-15" icon="exclamation-triangle" />
            Continue to Site
          </Link>
        </section>
      </div>
    </div>
  );
};

export default BrowserNotSupported;
