import React from "react";
import uuid from "uuid";
import { urlValidator } from "../../utils/httpUtils";
import PropTypes from "prop-types";

const Preposition = props => {
  const noLinks = props.link
    .map(item => {
      if (item.text === "" && item.url === "") {
        return false;
      }
      return undefined;
    })
    .includes(false);

  return (
    <section className="card block shadow--small mt-25">
      <h3 className="bold--lg">{`${props.index + 1}.`}</h3>
      <div className="mt-25">
        <table className="datalist mb-50">
          <tbody>
            <tr>
              <th
                className={`
                  datalist__header bold--md pl-25 datalist__header--first
                `}
                scope="row"
              >
                Definition:
              </th>
              <td
                className={`
                datalist__item pl-25 pr-25 datalist__item--first
              `}
              >
                {props.definition}
              </td>
            </tr>

            {!noLinks && (
              <tr>
                <th
                  className="datalist__header bold--md pl-25 pr-25"
                  scope="row"
                >
                  Definition Link(s):
                </th>
                <td className="datalist__item pl-25 pr-25">
                  <ul className="mt-5">
                    {props.link.map((item, index) => (
                      <li key={uuid() + index}>
                        <a
                          className="link-btn--light"
                          href={urlValidator(item.url)}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {item.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            )}

            <tr>
              <th
                className={`
                  datalist__header bold--md pl-25 datalist__header--last
                `}
                scope="row"
              >
                Type:
              </th>
              <td
                className={`
                datalist__item pl-25 pr-25 datalist__item--last
              `}
              >
                {props.grammaticalcase === "Two Way"
                  ? "Dative and Accusative"
                  : props.grammaticalcase}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

Preposition.propTypes = {
  index: PropTypes.number.isRequired
};

export default Preposition;
