import { TrayActions } from "../types";
const {
  UPDATE_RENDER_TRAY,
  UPDATE_TRAY_TOGGLE,
  UPDATE_BOTTOM_GRID,
  UPDATE_DELETE_GRID,
  RESET_TRAY
} = TrayActions;

export const updateRenderTray = payload => ({
  type: UPDATE_RENDER_TRAY,
  payload
});

export const updateTrayToggle = payload => ({
  type: UPDATE_TRAY_TOGGLE,
  payload
});

export const updateBottomGrid = payload => ({
  type: UPDATE_BOTTOM_GRID,
  payload
});

export const updateDeleteGrid = payload => ({
  type: UPDATE_DELETE_GRID,
  payload
});

export const resetTray = () => ({
  type: RESET_TRAY
});
