import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllWords } from "../../actions/HTTP-requests/getActions";
import { dictionaryReset } from "../../actions/components/dictionaryActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DictionaryView from "./DictionaryView";
import WordInfo from "../WordInfo";

const BackBTN = (props) => (
  <button
    type="button"
    className="bold--md link-btn--light mb-50"
    onClick={props.controlReset}
  >
    <FontAwesomeIcon icon="arrow-left" /> BACK TO DICTIONARY
  </button>
);

class Dictionary extends Component {
  state = {
    selectedWord: "",
    notFound: false,
  };

  componentDidMount() {
    this.props.getAllWords();
  }

  handleSearchUpdate = (word) => this.setState({ selectedWord: word });

  handleReset = () =>
    this.setState({ selectedWord: "", notFound: false }, () =>
      this.props.dictionaryReset()
    );

  handleNotFound = (value) => this.setState({ notFound: value });

  render() {
    const { notFound, selectedWord } = this.state;

    if (notFound) {
      return (
        <React.Fragment>
          <BackBTN controlReset={this.handleReset} />
          <p className="bold--md">Error: unable to lookup word.</p>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {selectedWord ? (
          <React.Fragment>
            <BackBTN controlReset={this.handleReset} />
            <WordInfo
              selectedWord={selectedWord}
              controlNotFound={this.handleNotFound}
            />
          </React.Fragment>
        ) : (
          <DictionaryView
            controlSearchUpdate={this.handleSearchUpdate}
            bottomGrid={this.props.bottomGrid}
            key={this.props.genericToggle}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  genericToggle: state.genericToggle.genericToggle,
});

const mapDispatchToProps = (dispatch) => ({
  dictionaryReset: () => dispatch(dictionaryReset()),
  getAllWords: () => dispatch(getAllWords()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dictionary);
