import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
// import MenuButton from "../MenuButton";
import logo from "../../assets/images/GC-Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { updateBackButton } from "../../actions/pages/assignmentActions";

let resize;

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { isMobile: false, directionClick: false };
    this.onDirectionClick = this.onDirectionClick.bind(this);
  }

  onDirectionClick() {
    this.setState({
      directionClick: true,
    });
  }

  componentDidMount() {
    this.handleSize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    clearTimeout(resize);
    resize = setTimeout(this.handleSize, 250);
  };

  handleSize = () => {
    switch (true) {
      case window.innerWidth <= 1024:
        return this.setState({ isMobile: true });
      default:
        return this.setState({ isMobile: false });
    }
  };

  goBack = () => {
    window.history.back();
  };

  render() {
    let backTitle;

    switch (this.props.pageTitle) {
      case "Select Course and Section":
        backTitle = "";
        break;
      case "Assignments":
        backTitle = "Course and Section";
        break;
      case "Directions":
        backTitle = "Assignments";
        break;
      default:
    }

    const Desktop = (
      <nav className="navigation nav--desktop">
        <div className="logo">
          <a className="nav__skip-link" href="#gc-header">
            Skip to main content
          </a>
          <div className="nav__logo-container">
            <NavLink to="/">
              <img
                className="nav__logo"
                src={logo}
                alt="German Grammar Cruncher"
              />
            </NavLink>
          </div>
        </div>
        <div className="bold--md nav__logo-name">
          <NavLink to="/">Grammar Cruncher</NavLink>
        </div>
        <div className="nav__list-container">
          <ul className="nav__list">
            <li className="nav__list-item">
              <NavLink
                activeClassName="bold--md nav-link--selected"
                className="bold--md nav-link"
                exact={true}
                to="/"
              >
                Select Course
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    );

    const Mobile = (
      <nav
        className={`
        nav--mobile
        ${
          this.props.menuState
            ? "nav__mobile-bg--open"
            : "nav__mobile-bg--closed"
        }
      `}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <>
            {this.props.pageTitle === "Language Set" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => {
                  this.props.updateBackButton();
                }}
              >
                <div>
                  <FontAwesomeIcon icon={faAngleLeft} size="3x" color="black" />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "1.2em",
                    paddingLeft: "5px",
                    color: "black",
                  }}
                >
                  Directions
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={this.goBack}
            >
              <div>
                {backTitle ? (
                  <FontAwesomeIcon icon={faAngleLeft} size="3x" color="black" />
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "1.2em",
                  paddingLeft: "5px",
                  color: "black",
                }}
              >
                {backTitle}
              </div>
            </div>
          </>
          <h1
            style={{
              color: "black",
              fontSize: "24px",
            }}
          >
            {this.props.pageTitle !== "Language Set"
              ? backTitle
                ? ""
                : "Grammar Cruncher"
              : null}
          </h1>
          <img
            className="nav__logo--mobile"
            src={logo}
            alt="German Grammar Cruncher"
          />
          {/* <MenuButton /> */}
        </div>
        {this.props.menuState && (
          <div className="nav__menu">
            <ul className="nav__list">
              <li className="nav__list-item">
                <NavLink
                  activeClassName="bold--md nav-link--selected"
                  className="bold--md nav-link"
                  exact={true}
                  to="/"
                >
                  Change Course
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </nav>
    );

    return (
      <React.Fragment>
        {!this.state.isMobile && Desktop}
        {this.state.isMobile && Mobile}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  menuState: state.menuBTN.toggleMenu,
  courseID: state.route.courseID,
  sectionID: state.route.sectionID,
  pageTitle: state.mainContainer.pageTitle,
  assignmentName: state.get.assignment.name,
});

const mapDispatchToProps = (dispatch) => ({
  updateBackButton: (value) => dispatch(updateBackButton(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
