import React from "react";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import PropTypes from "prop-types";

const List = props => {
  const headers = Object.keys(props.list).filter(header => props.list[header]);
  return (
    <table className="datalist">
      <tbody>
        {headers.map((header, index) => (
          <tr key={index}>
            <th
              className={`
                  datalist__header 
                  bold--md
                  pl-25
                  pr-25
                  ${index === 0 ? "datalist__header--first" : ""}
                  ${
                    index === headers.length - 1 ? "datalist__header--last" : ""
                  }
                `}
              scope="row"
            >
              {capitalizeFirstLetter(header)}
            </th>
            <td
              className={`
                  datalist__item
                  pl-25
                  pr-25 
                  ${index === 0 ? "datalist__item--first" : ""}
                  ${index === headers.length - 1 ? "datalist__item--last" : ""}
                `}
              lang={props.lang}
            >
              {props.list[header]}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

List.defaultProps = {
  lang: "en"
};

List.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.object.isRequired
};

export default List;
