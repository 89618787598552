import { combineReducers } from "redux";

import assignmentReducer from "./pages/assignmentReducer";
import dictionaryReducer from "./components/dictionaryReducer";
import getReducer from "./HTTP-requests/getReducer";
import MainContainerReducer from "./components/MainContainerReducer";
import menuBTNReducer from "./components/menuBTNReducer";
import routeReducer from "./routes/routeReducer";
import searchFilterReducer from "./components/searchFilterReducer";
import tabListReducer from "./components/tabListReducer";
import trayReducer from "./components/trayReducer";
import dropContainerReducer from "./components/dropContainerReducer";
import combinedWordsReducer from "./components/combinedWordsReducer";
import genericToggleReducer from "./components/genericToggleReducer";

export default combineReducers({
  assignment: assignmentReducer,
  dictionary: dictionaryReducer,
  get: getReducer,
  mainContainer: MainContainerReducer,
  menuBTN: menuBTNReducer,
  route: routeReducer,
  searchFilter: searchFilterReducer,
  tabList: tabListReducer,
  tray: trayReducer,
  dropContainer: dropContainerReducer,
  combinedWords: combinedWordsReducer,
  genericToggle: genericToggleReducer,
});
