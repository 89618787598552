import React from "react";
import Footer from "../components/Footer";
import MainContainer from "../components/MainContainer";
import Navbar from "../components/Navbar";
import PropTypes from "prop-types";

const MainLayout = props => (
  <div id="main-root" className="layout" tabIndex="-1">
    <Navbar />
    <MainContainer>{props.children}</MainContainer>
    <Footer />
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default MainLayout;
