import { CombinedWordActions } from "../../actions/types";
const { UPDATE_COMBINED_WORDS } = CombinedWordActions;

const initialState = {
  combinedWordsList: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_COMBINED_WORDS:
      if (action.payload === "reset") {
        return {
          ...state,
          combinedWordsList: [],
        };
      } else if (action.payload === "undo") {
        let newArray = [...state.combinedWordsList];
        let evenNewerArray = newArray.slice(0, -1);
        return {
          ...state,
          combinedWordsList: evenNewerArray,
        };
      } else {
        return {
          ...state,
          combinedWordsList: [...state.combinedWordsList, action.payload],
        };
      }
    default:
      return state;
  }
}
