// REACT AND ROUTER
import React from "react";
import uuid from "uuid";
import { urlValidator } from "../../utils/httpUtils";
import PropTypes from "prop-types";

const Pronoun = props => {
  const noLinks = props.link
    .map(item => {
      if (item.text === "" && item.url === "") return false;
      return undefined;
    })
    .includes(false);

  return (
    <section className="card block shadow--small mt-25">
      <h3 className="bold--lg">{`${props.index + 1}.`}</h3>
      <div className="mt-25">
        <table className="datalist mb-50">
          <tbody>
            <tr>
              <th
                className="datalist__header bold--md pl-25 datalist__header--first"
                scope="row"
              >
                Definition:
              </th>
              <td className="datalist__item pl-25 pr-25 datalist__item--first">
                {props.definition}
              </td>
            </tr>

            {!noLinks && (
              <tr>
                <th
                  className="datalist__header bold--md pl-25 pr-25"
                  scope="row"
                >
                  Definition Link(s):
                </th>
                <td className="datalist__item pl-25 pr-25">
                  <ul className="mt-5">
                    {props.link.map((item, index) => (
                      <li key={uuid() + index}>
                        <a
                          className="link-btn--light"
                          href={urlValidator(item.url)}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {item.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            )}

            <tr>
              <th
                className={`
                  datalist__header 
                  bold--md
                  pl-25
                  pr-25
                `}
                scope="row"
              >
                Gender:
              </th>
              <td
                className={`
                  datalist__item
                  pl-25
                  pr-25 
                `}
              >
                {props.gender}
              </td>
            </tr>

            <tr>
              <th
                className={`
                  datalist__header 
                  bold--md
                  pl-25
                  pr-25
                `}
                scope="row"
              >
                Number:
              </th>
              <td
                className={`
                  datalist__item
                  pl-25
                  pr-25 
                `}
              >
                {props.number}
              </td>
            </tr>

            <tr>
              <th
                className={`
                  datalist__header 
                  bold--md
                  pl-25
                  pr-25
                `}
                scope="row"
              >
                Reflexive:
              </th>
              <td
                className={`
                  datalist__item
                  pl-25
                  pr-25 
                `}
              >
                {Number(props.reflexive) === 0 ? "No" : "Yes"}
              </td>
            </tr>

            <tr>
              <th
                className={`
                  datalist__header 
                  bold--md
                  pl-25
                  pr-25
                `}
                scope="row"
              >
                Person:
              </th>
              <td
                className={`
                  datalist__item
                  pl-25
                  pr-25 
                `}
              >
                {props.person}
              </td>
            </tr>

            <tr>
              <th
                className={`
                  datalist__header 
                  bold--md
                  pl-25
                  pr-25
                  datalist__header--last
                `}
                scope="row"
              >
                Case:
              </th>
              <td
                className={`
                  datalist__item
                  pl-25
                  pr-25 
                  datalist__item--last
                `}
              >
                {props.grammaticalcase}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

Pronoun.propTypes = {
  index: PropTypes.number.isRequired
};

export default Pronoun;
