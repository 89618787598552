import React, { Component } from "react";
import uuid from "uuid";
import PropTypes from "prop-types";

class AssignmentTabs extends Component {
  state = { 
    list: [] 
  };

  componentDidMount() {
    const { children } = this.props;
    const list = [];
    document.addEventListener("keydown", this.handleTab);
    children.map((item, index) =>
      list.push({
        tabID: uuid(),
        sectionID: uuid(),
        selected: index === 0 ? true : false,
        tabIndex: index === 0 ? "0" : "-1",
      })
    );
    this.setState({ list, index: 0 });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleTab);
  }

  handleTab = (e) => {
    const key = e.which || e.keyCode;
    const direction = this.handleDirection(key);

    if (direction) {
      this.state.list.map((item) => {
        if (item.tabID === document.activeElement.id) {
          return this.handleKeyNav(direction);
        }
        return undefined;
      });
    }
  };

  handleDirection = (key) => {
    switch (key) {
      case 37:
        return -1;
      case 39:
        return 1;
      case 40:
        return 1;
      case 38:
        return -1;
      default:
        return false;
    }
  };

  handleKeyNav = (direction) => {
    const index = this.handleIndex(direction);

    this.setState({ index }, () => this.handleShowSection(this.state.index));
  };

  handleIndex = (direction) => {
    const { list, index } = this.state;

    if (list.length - 1 < index + direction) {
      return index;
    } else if (0 > index + direction) {
      return index;
    } else {
      return index + direction;
    }
  };

  handleShowSection = (index) => {
    this.setState({
      list: this.state.list.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            selected: true,
            tabIndex: "0",
          };
        }

        return {
          ...item,
          selected: false,
          tabIndex: "-1",
        };
      }),
    });
  };

  render() {
    const { children, tabHeaders } = this.props;
    const { list, focus } = this.state;
    let selectedIndex;
    list.forEach((item, i) => item.selected && (selectedIndex = i));

    return (
      <div className="assignment-list">
        <div
          className={`
          assignment-list__container
          ${focus ? "assignment-list__container--selected" : ""}
        `}
        >
          <ul className="assignment-list__header">
            {children.map((item, index) => {
              if (list.length > 0) {
                return (
                  <li
                    role="presentation"
                    key={list[index].tabID}
                    className={`
                      assignment-list__list-item
                      ${
                        list[index].selected &&
                        `assignment-list__list-item--selected`
                      }
                    `}
                  >
                    <button
                      type="button"
                      onFocus={() => this.setState({ focus: true })}
                      onBlur={() => this.setState({ focus: false })}
                      id={list[index].tabID}
                      role="tab"
                      aria-selected={list[index].selected}
                      tabIndex={list[index].tabIndex}
                      onClick={() => this.handleShowSection(index)}
                      className={`
                        assignment-list__btn
                        bold--lg
                        ${index === 0 && "assignment-list__btn--first"}
                        ${
                          index === list.length - 1 &&
                          "assignment-list__btn--last"
                        }
                        ${
                          index === selectedIndex - 1 &&
                          "assignment-list__btn--before"
                        }
                        ${
                          index === selectedIndex + 1 &&
                          "assignment-list__btn--after"
                        }
                      `}
                    >
                      {tabHeaders[index]}
                    </button>
                  </li>
                );
              }
              return <div key={index}></div>;
            })}
          </ul>
          <div
            className={`
            assignment-list__spacer
            ${
              list.length > 0 &&
              list[list.length - 1].selected &&
              "assignment-list__spacer-last-item"
            }
          `}
          />
        </div>

        {list.length > 0 &&
          this.props.children.map((item, index) => {
            if (list[index].selected) {
              return (
                <section
                  id={list[index].sectionID}
                  role="tabpanel"
                  aria-labelledby={list[index].tabID}
                  className="assignment-list__body"
                  key={list[index].sectionID}
                >
                  {item}
                </section>
              );
            }
            return <div key={index}></div>;
          })}
      </div>
    );
  }
}

AssignmentTabs.propTypes = {
  tabHeaders: PropTypes.array.isRequired,
};

export default AssignmentTabs;
