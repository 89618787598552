/* An array specified by a key value pair within an array of objects is matched against the values within an array. */
export const compareArr = (arr, arrOfObj, keyName) => {
  const result = [];
  // iterate over each object in arrOfObj
  for (let i = 0; i < arrOfObj.length; i++) {
    // check if specified array in arrOfObj contains the same values in arr
    // If values match, push into new array assigned to compare
    const compare = arrOfObj[i][keyName].filter(item => arr.includes(item));
    // compare length needs the same length as arr, if true push to result
    if (compare.length === arr.length) result.push(arrOfObj[i]);
  }
  return result;
};

export function strToArray(str) {
  return str.split(/(\s+)/u).filter(e => e.trim().length > 0);
}

export function arrHalfSplit(arr, num) {
  const length = Math.ceil(arr.length / 2);
  let newArr = [];
  if (num === 1) {
    for (let i = 0; i < length; i++) {
      if (i === 0) {
        newArr = [arr[i]];
        continue;
      }
      newArr = [...newArr, arr[i]];
    }
    return newArr;
  }

  if (num === 2) {
    for (let i = length; i < arr.length; i++) {
      if (i === length) {
        newArr = [arr[i]];
        continue;
      }
      newArr = [...newArr, arr[i]];
    }
    return newArr;
  }

  return;
}
