// ######################## COMPONENTS > Generic Toggle ########################
export const GenericActions = {
  UPDATE_TOGGLE: "UPDATE_TOGGLE",
  UPDATE_PHRASE_BUILDER_TOGGLE: "UPDATE_PHRASE_BUILDER_TOGGLE",
  UPDATE_PHRASE_BUILDER_MULTI_SELECT: "UPDATE_PHRASE_BUILDER_MULTI_SELECT",
};

// ######################## COMPONENTS > Dictionary ########################
export const DictionaryActions = {
  DICTIONARY_RESET: "DICTIONARY_RESET",
  DICTIONARY_SEARCH_VALUE: "DICTIONARY_SEARCH_VALUE",
  DICTIONARY_UPDATE_TOGGLE_FILTER: "DICTIONARY_UPDATE_TOGGLE_FILTER",
};

// ######################## COMPONENTS > Main Container ########################
export const HeaderActions = {
  UPDATE_TITLE: "UPDATE_TITLE",
  UPDATE_COURSE: "UPDATE_COURSE",
  UPDATE_SECTION: "UPDATE_SECTION",
};

// ######################## COMPONENTS > Drag/Drop Container ########################
export const DragDropActions = {
  UPDATE_DROPPED_ITEMS: "UPDATE_DROPPED_ITEMS",
  UPDATE_DROPPED_ID: "UPDATE_DROPPED_ID",
  UPDATE_SHOW_PHRASE: "UPDATE_SHOW_PHRASE",
};

// ######################## COMPONENTS > Combined Words ########################
export const CombinedWordActions = {
  UPDATE_COMBINED_WORDS: "UPDATE_COMBINED_WORDS",
};

// ######################## COMPONENTS > MENU BUTTON ########################
export const MenuBTNActions = {
  TOGGLE_MENU: "TOGGLE_MENU",
};

// ######################## COMPONENTS > SEARCH FILTER ########################
export const SearchFilterActions = {
  ALPHABETICAL_ADD_FILTER: "ALPHABETICAL_ADD_FILTER",
  ALPHABETICAL_REMOVE_FILTER: "ALPHABETICAL_REMOVE_FILTER",
  CLEAR_FILTERED_LIST: "CLEAR_FILTERED_LIST",
  POSFILTER_ADD_FILTER: "POSFILTER_ADD_FILTER",
  POSFILTER_REMOVE_FILTER: "POSFILTER_REMOVE_FILTER",
  RESET_SEARCH_FILTER: "RESET_SEARCH_FILTER",
  UPDATE_FILTERED_LIST: "UPDATE_FILTERED_LIST",
};

// ######################## COMPONENTS > TAB LIST ########################
export const TabListActions = {
  UPDATE_INDEX: "UPDATE_INDEX",
};

// ######################## COMPONENTS > TRAY ########################
export const TrayActions = {
  UPDATE_RENDER_TRAY: "UPDATE_RENDER_TRAY",
  UPDATE_TRAY_TOGGLE: "UPDATE_TRAY_TOGGLE",
  UPDATE_BOTTOM_GRID: "UPDATE_BOTTOM_GRID",
  UPDATE_DELETE_GRID: "UPDATE_DELETE_GRID",
  RESET_TRAY: "RESET_TRAY",
};

// ######################## HTTP-requests > GET ########################
export const GetActions = {
  GET_ALL_ASSIGNMENTS: "GET_ALL_ASSIGNMENTS",
  GET_SECTION_ASSIGNMENTS: "GET_SECTION_ASSIGNMENTS",
  GET_ALL_WORDS: "GET_ALL_WORDS",
  GET_COURSES: "GET_COURSES",
  GET_SPECIFIC_ASSIGNMENT: "GET_SPECIFIC_ASSIGNMENT",
  GET_SPECIFIC_WORD: "GET_SPECIFIC_WORD",
  SESSION_EXPIRED: "SESSION_EXPIRED",
};

// ######################## PAGES > ASSIGNMENT ########################
export const AssignmentActions = {
  RESET_ASSIGNMENT: "RESET_ASSIGNMENT",
  UPDATE_POSITIONS: "UPDATE_POSITIONS",
  UPDATE_DRAG_CLONE: "UPDATE_DRAG_CLONE",
  UPDATE_SENTENCE: "UPDATE_SENTENCE",
  UPDATE_ASSIGNMENT_NAME: "UPDATE_ASSIGNMENT_NAME",
  UPDATE_BACK_BUTTON: "UPDATE_BACK_BUTTON",
};

// ######################## ROUTES ########################
export const RouteActions = {
  UPDATE_ASSIGNMENT_ID: "UPDATE_ASSIGNMENT_ID",
  UPDATE_COURSE_ID: "UPDATE_COURSE_ID",
  UPDATE_SECTION_ID: "UPDATE_SECTION_ID",
};
