import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getAllCourses } from "../actions/HTTP-requests/getActions";
import {
  updateCourseID,
  updateSectionID,
} from "../actions/routes/routeActions";
import {
  updateCourse,
  updateSection,
} from "../actions/components/headerActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageTemplate from "../components/PageTemplate";
import AutoComplete from "../components/AutoComplete";

class SelectCourse extends Component {
  state = {
    courseSearchTerm: "",
    courseSearchObj: "",
    sectionSearchTerm: "",
    sectionSearchObj: "",
  };

  componentDidMount() {
    this.props.getAllCourses();
  }

  updateCourseSearch = (value, obj) => {
    if (obj) {
      this.props.updateCourseID(obj.id);
      this.props.updateCourse(obj.name);
    }

    return this.setState({
      courseSearchTerm: value,
      courseSearchObj: obj || "",
    });
  };

  updateSectionSearch = (value, obj) => {
    if (obj) {
      this.props.updateSectionID(obj.id);
      this.props.updateSection(obj.name);
    }

    return this.setState({
      sectionSearchTerm: value,
      sectionSearchObj: obj || "",
    });
  };

  handleClearCourseSearch = () => {
    this.props.updateCourseID("");
    this.props.updateSectionID("");
    this.props.updateCourse("");
    this.props.updateSection("");
    return this.setState({
      courseSearchTerm: "",
      courseSearchObj: "",
      sectionSearchTerm: "",
      sectionSearchObj: "",
    });
  };

  handleClearSectionSearch = () => {
    this.props.updateSectionID("");
    return this.setState({
      sectionSearchTerm: "",
      sectionSearchObj: "",
    });
  };

  render() {
    const { courseSearchObj } = this.state;
    const sectionArr = courseSearchObj ? courseSearchObj.section : [];
    return (
      <PageTemplate title="Select Course and Section">
        <div className="inset mb-50">
          <AutoComplete
            controlClear={this.handleClearCourseSearch}
            controlUpdate={this.updateCourseSearch}
            errorMsg="No courses to display"
            keyName="name"
            label="Course:"
            list={this.props.courses}
            placeholder="e.g. GER5006"
            searchValue={this.state.courseSearchTerm}
            type="dropdown"
          />
        </div>
        <div className="inset mb-50">
          <AutoComplete
            controlClear={this.handleClearSectionSearch}
            controlUpdate={this.updateSectionSearch}
            errorMsg="Select course to display sections"
            keyName="name"
            label="Section:"
            list={sectionArr}
            placeholder="e.g. T600"
            searchValue={this.state.sectionSearchTerm}
            type="dropdown"
          />
        </div>
        <div className="flex flex-end">
          <Link
            className={`
                ${
                  this.state.sectionSearchObj
                    ? "primary-btn"
                    : "disabled-btn disabled-link"
                }
                bold--md 
              `}
            to={{
              pathname: `/assignments`,
              search: `c=${encodeURIComponent(
                this.props.courseID
              )}&s=${encodeURIComponent(this.props.sectionID)}`,
            }}
          >
            <span className="mr-10">Next</span>
            <FontAwesomeIcon icon="arrow-right" />
          </Link>
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  courses: state.get.allCourses,
  courseID: state.route.courseID,
  sectionID: state.route.sectionID,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCourses: () => dispatch(getAllCourses()),
  updateCourseID: (value) => dispatch(updateCourseID(value)),
  updateSectionID: (value) => dispatch(updateSectionID(value)),
  updateCourse: (value) => dispatch(updateCourse(value)),
  updateSection: (value) => dispatch(updateSection(value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectCourse)
);
