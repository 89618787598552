import React, { Component } from "react";
import Muuri from "muuri";
import PropTypes from "prop-types";

class DeleteContainer extends Component {
  deleteDropTarget = React.createRef();

  componentDidMount() {
    const { gridInstance } = this.props;

    const grid = new Muuri(this.deleteDropTarget.current, {
      dragEnabled: true,
      dragContainer: document.body,
    })
      .on("send", () => {
        this.deleteDropEffects("remove");
      })
      .on("receive", () => {
        this.deleteDropEffects("add");
      })
      .on("dragReleaseEnd", (item) => {
        grid.hide(item, {
          onFinish: (items) => {
            grid.remove(item, { removeElements: true });
          },
        });
        this.deleteDropEffects("remove");
      });

    if (gridInstance) gridInstance(grid);
  }

  deleteDropEffects = (type) => {
    const { parentID } = this.props;

    if (parentID) {
      document.getElementById(parentID).classList[type]("dnd__delete-bg");
    }

    this.deleteDropTarget.current.classList[type]("dnd__delete-hover");
  };

  render() {
    return (
      <section className='dnd__delete-container'>
        <div ref={this.deleteDropTarget} className='dnd__delete-target' />
      </section>
    );
  }
}

DeleteContainer.propTypes = {
  gridInstance: PropTypes.func,
  parentID: PropTypes.string,
};

export default DeleteContainer;
