import { RouteActions } from "../types";
const {
  UPDATE_ASSIGNMENT_ID,
  UPDATE_COURSE_ID,
  UPDATE_SECTION_ID
} = RouteActions;

export const updateAssignmentID = payload => ({
  type: UPDATE_ASSIGNMENT_ID,
  payload
});

export const updateCourseID = payload => ({
  type: UPDATE_COURSE_ID,
  payload
});

export const updateSectionID = payload => ({
  type: UPDATE_SECTION_ID,
  payload
});
