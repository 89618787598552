import { GenericActions } from "../../actions/types";
const {
  UPDATE_TOGGLE,
  UPDATE_PHRASE_BUILDER_TOGGLE,
  UPDATE_PHRASE_BUILDER_MULTI_SELECT,
} = GenericActions;

const initialState = {
  genericToggle: false,
  phraseBuilderToggle: false,
  phraseBuilderMultiSelect: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_TOGGLE:
      return {
        ...state,
        genericToggle: !state.genericToggle,
      };
    case UPDATE_PHRASE_BUILDER_TOGGLE:
      return {
        ...state,
        phraseBuilderToggle: !state.phraseBuilderToggle,
      };
    case UPDATE_PHRASE_BUILDER_MULTI_SELECT:
      if (action.payload === false) {
        return {
          ...state,
          phraseBuilderMultiSelect: action.payload,
        };
      } else {
        return {
          ...state,
          phraseBuilderMultiSelect: !state.phraseBuilderMultiSelect,
        };
      }
    default:
      return state;
  }
}
