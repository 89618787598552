export const filterList = (
  value,
  list,
  keyName,
  returnBlank = false,
  limit = false
) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  const result = [];

  if (returnBlank && inputLength === 0) {
    return result;
  }

  for (let i = 0; i < list.length; i++) {
    if (limit && result.length === 9) break;

    const listItemValue = keyName
      ? list[i][keyName].toLowerCase().slice(0, inputLength)
      : list[i].toLowerCase().slice(0, inputLength);

    if (listItemValue === inputValue) {
      result.push(list[i]);
    }
  }

  return result;
};

export const updateSearch = (event, newValue, list, controlUpdate, keyName) => {
  if (typeof newValue === "object") {
    return controlUpdate(newValue.name, newValue);
  }

  const match = list.filter(item => item[keyName] === event.target.value);
  const obj = match.length > 0 ? match[0] : "";

  return controlUpdate(event.target.value, obj);
};
