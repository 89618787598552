// ############### REACT & REDUX ###############
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
//import configureStore from "./utils/configureStore";
import store from "./store";

// ############### ROUTES ###############
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "./routes";

// ############### LIBS ###############
import Bowser from "bowser/bundled";
import BrowserNotSupported from "./pages/BrowserNotSupported";
import { library } from "@fortawesome/fontawesome-svg-core";
import fontAwesomeIcons from "./utils/fontAwesomeIcons";

// ############### STYLES ###############
import "./styles/main.scss";

library.add(fontAwesomeIcons);

class Index extends Component {
  state = { isValidBrowser: false };

  componentDidMount() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidBrowser = browser.satisfies({
      chrome: ">=57",
      safari: ">=10.1",
      firefox: ">=52",
      opera: ">=44",
      "Microsoft Edge": ">=16",
      "Internet Explorer": ">=12",
    });
    this.setState({ isValidBrowser });
  }

  NotSupportedWrapper = (rest) => (
    <BrowserNotSupported
      controlIsValid={() => this.setState({ isValidBrowser: true })}
      {...rest}
    />
  );

  render() {
    if (!this.state.isValidBrowser) {
      return (
        <Router>
          <Switch>
            <Route component={this.NotSupportedWrapper} />
          </Switch>
        </Router>
      );
    }

    return <Routes />;
  }
}

ReactDOM.render(
  <Provider store={store}>
    <Index />
  </Provider>,
  document.getElementById("root")
);
