import React from "react";
import { connect } from "react-redux";
import DragContainer from "../DragAndDrop/DragContainer";
/* import { capitalizeFirstLetter } from "../../utils/stringUtils"; */
import { strToArray } from "../../utils/arrUtils";
import PropTypes from "prop-types";

const DragList = (props) => {
  const headers = Object.keys(props.list).filter(
    (header) => props.list[header]
  );
  return (
    <div className="datalist--drag" role="table">
      {headers.map((header, index) => (
        <div className="datalist__row" key={index} role="rowgroup">
          <div
            className={`
                  datalist__header 
                  datalist__header--drag
                  bold--md
                  pl-25
                  pr-25
                  ${index === 0 ? "datalist__header--first" : ""}
                  ${
                    index === headers.length - 1 ? "datalist__header--last" : ""
                  }
                `}
            role="rowheader"
          >
           {/* capitalizeFirstLetter(header) */}
            {header}
          </div>
          <div
            className={`
                  datalist__item
                  pl-25
                  pr-25 
                  ${index === 0 ? "datalist__item--first" : ""}
                  ${index === headers.length - 1 ? "datalist__item--last" : ""}
                `}
            lang={props.lang}
            role="cell"
          >
            <DragContainer
              dragInto={props.bottomGrid}
              list={strToArray(props.list[header])}
              key={props.genericToggle}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

DragList.defaultProps = {
  lang: "en",
};

DragList.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bottomGrid: state.tray.bottomGrid,
  genericToggle: state.genericToggle.genericToggle,
});

export default connect(mapStateToProps)(DragList);
