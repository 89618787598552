import React, { Component } from "react";
import Dropdown from "../Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class Pagination extends Component {
  state = {
    config: {},
    inputValue: "",
    numDisplayedItems: 20
  };

  componentDidMount() {
    // Set page if list isn't empty
    if (this.props.list && this.props.list.length) {
      this.handleDisplayedItems();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Reset page if list has changed
    if (
      this.props.list !== prevProps.list ||
      this.state.numDisplayedItems !== prevState.numDisplayedItems
    ) {
      this.handleDisplayedItems();
    }
  }

  handleDisplayedItems = (page, startIndex) => {
    let { list } = this.props;
    let config = this.state.config;

    if (page < 1) {
      page = 1;
    } else if (page > config.totalPages) {
      page = config.totalPages;
    }

    // Get new config object for specified page
    config = this.handleConfig(list.length, page, startIndex);

    // Get new page of items from list
    const pageOfItems = list.slice(config.startIndex, config.endIndex + 1);

    // Update state
    this.setState({ config });

    // Call change page function in parent component
    this.props.handleResult(pageOfItems, config.startIndex, config.endIndex);
  };

  handleConfig = (totalItems, page, index) => {
    const totalPages = Math.ceil(totalItems / this.state.numDisplayedItems);
    let currentPage;

    if (index) {
      // Calculate number of times numDisplayItems goes into index
      // Result will determine what page currentPage should be.
      const indexPage = Math.floor(index / this.state.numDisplayedItems);
      currentPage = indexPage < 1 ? 1 : indexPage + 1;

      // If startIndex has not been set then currentPage will be
      // last page, unless last page has not been set then default to 1.
    } else {
      currentPage = page || 1;
    }

    // If startIndex has already been set and currentPage is greater than 1,
    // then startIndex will remain the same
    const startIndex =
      currentPage > 1 && index
        ? index
        : (currentPage - 1) * this.state.numDisplayedItems;

    const endIndex = Math.min(
      startIndex + this.state.numDisplayedItems - 1,
      totalItems - 1
    );

    // Return object with all pagination properties required by the view
    return {
      totalItems,
      currentPage,
      numDisplayedItems: this.state.numDisplayedItems,
      totalPages,
      startIndex,
      endIndex
    };
  };

  handleInput = event => {
    const key = event.keyCode || event.which;
    if (key === 13) {
      this.handleDisplayedItems(this.state.inputValue);
    }
  };

  render() {
    const { config, inputValue } = this.state;
    let mobile = false;

    if (window.matchMedia("(max-width: 1024px)").matches) {
      mobile = true;
    }

    if (!config.totalPages && config.totalPages < 1) {
      return null;
    }

    return (
      <nav aria-label="pagination" className="pagination__grid inset">
        <div className="pagination__show pagination__grid-1-of-3">
          <span aria-hidden="true" className="mr-5">
            Show
          </span>
          <Dropdown
            ariaLabel="items shown per page"
            classNames="ml-15 mr-15"
            controlSelectedOption={num =>
              this.setState(
                {
                  numDisplayedItems: Number(num.target.value)
                },
                () => this.handleDisplayedItems(undefined, config.startIndex)
              )
            }
            options={[20, 50, 100, 200, 500]}
          />
          <span className="bundle ml-5" aria-hidden="true">
            per page
          </span>
        </div>

        <div className="pagination__grid-1-of-3">
          <div className="pagination__jump">
            Go to page
            <div className="bundle">
              <input
                aria-label="go to page"
                className="pagination__input ml-15"
                onKeyDown={this.handleInput}
                onChange={event =>
                  this.setState({
                    inputValue: Number(event.target.value)
                  })
                }
                type="number"
                pattern="[0-9]*"
              />
              <button
                aria-label="Go to page"
                className="pagination__btn--right"
                onClick={() => this.handleDisplayedItems(inputValue)}
              >
                Go
              </button>
            </div>
          </div>
        </div>

        <div className="pagination__grid-1-of-3 tablet-full">
          {!mobile && (
            <div className="pagination__nav">
              Page {config.currentPage} of {config.totalPages}
              <div className="bundle">
                <button
                  aria-disabled={config.currentPage === 1 ? "true" : "false"}
                  aria-label="Previous page"
                  className={`
                    ml-15
                    ${
                      config.currentPage === 1
                        ? "pagination__btn--disabled-left"
                        : "pagination__btn--left"
                    }
                  `}
                  onClick={() =>
                    this.handleDisplayedItems(config.currentPage - 1)
                  }
                >
                  <FontAwesomeIcon icon="chevron-left" />
                </button>
                <button
                  aria-disabled={
                    config.currentPage === config.totalPages ? "true" : "false"
                  }
                  aria-label="Next page"
                  className={
                    config.currentPage === config.totalPages
                      ? "pagination__btn--disabled-right"
                      : "pagination__btn--right"
                  }
                  onClick={() =>
                    this.handleDisplayedItems(config.currentPage + 1)
                  }
                >
                  <FontAwesomeIcon icon="chevron-right" />
                </button>
              </div>
            </div>
          )}

          {mobile && (
            <React.Fragment>
              <hr />
              Page {config.currentPage} of {config.totalPages}
              <div className="pagination__nav--mobile mt-15">
                <button
                  aria-disabled={config.currentPage === 1 ? "true" : "false"}
                  aria-label="Previous page"
                  className={`
                    pagination__btn--mobile
                    ${
                      config.currentPage === 1
                        ? "pagination__btn--disabled-left"
                        : "pagination__btn--left"
                    }
                  `}
                  onClick={() =>
                    this.handleDisplayedItems(config.currentPage - 1)
                  }
                >
                  <FontAwesomeIcon icon="chevron-left" />
                </button>
                <button
                  aria-disabled={
                    config.currentPage === config.totalPages ? "true" : "false"
                  }
                  aria-label="Next page"
                  className={`
                    pagination__btn--mobile
                    ${
                      config.currentPage === config.totalPages
                        ? "pagination__btn--disabled-right"
                        : "pagination__btn--right"
                    }
                  `}
                  onClick={() =>
                    this.handleDisplayedItems(config.currentPage + 1)
                  }
                >
                  <FontAwesomeIcon icon="chevron-right" />
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
      </nav>
    );
  }
}

Pagination.propTypes = {
  handleResult: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired
};

export default Pagination;
