import React from "react";
import { connect } from "react-redux";
import {
  removeAlphabeticalFilter,
  removePOSFilter,
  resetSearchFilter
} from "../../actions/components/searchFilterActions";
import uuid from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const Button = props =>
  props.filter.appliedFilters.map(appliedFilter => (
    <button
      className="filter-btn--applied bold--sm mr-15 mt-15"
      key={uuid()}
      onClick={() =>
        props.removeFilter(appliedFilter).then(() => props.controlFilter())
      }
    >
      {appliedFilter}
      <FontAwesomeIcon className="ml-15" icon="times-circle" size="sm" />
    </button>
  ));

const AppliedFilters = props => {
  const {
    controlFilter,
    removeAlphabeticalFilter,
    removePOSFilter,
    resetSearchFilter,
    selectedFilters
  } = props;

  const empty = selectedFilters.every(filter =>
    props[filter].appliedFilters.length === 0 ? true : false
  );

  if (selectedFilters === undefined || empty) {
    return null;
  }

  return (
    <div className="card shadow--small block mt-25">
      <p className="bold--md">Applied Filters:</p>
      {selectedFilters.map(filter => {
        switch (filter) {
          case "AlphabeticalFilter":
            return (
              <Button
                key={uuid()}
                filter={props[filter]}
                removeFilter={removeAlphabeticalFilter}
                controlFilter={controlFilter}
              />
            );
          case "POSFilter":
            return (
              <Button
                key={uuid()}
                filter={props[filter]}
                removeFilter={removePOSFilter}
                controlFilter={controlFilter}
              />
            );
          default:
            return null;
        }
      })}
      <button
        className="filter-btn--applied-clear-all bold--sm mr-15 mt-15"
        onClick={() => resetSearchFilter().then(() => controlFilter())}
      >
        Clear All
        <FontAwesomeIcon className="ml-15" icon="times-circle" size="sm" />
      </button>
    </div>
  );
};

AppliedFilters.propTypes = {
  controlFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  AlphabeticalFilter: state.searchFilter.AlphabeticalFilter,
  POSFilter: state.searchFilter.POSFilter
});

const mapDispatchToProps = dispatch => ({
  removeAlphabeticalFilter: async value =>
    dispatch(removeAlphabeticalFilter(value)),
  removePOSFilter: async value => dispatch(removePOSFilter(value)),
  resetSearchFilter: async () => dispatch(resetSearchFilter())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppliedFilters);
