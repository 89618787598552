import { TabListActions } from "../../actions/types";
const { UPDATE_INDEX } = TabListActions;

const initialState = {
  selectedIndex: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_INDEX:
      return {
        ...state,
        selectedIndex: action.payload
      };
    default:
      return state;
  }
}
