import { DragDropActions } from "../../actions/types";
const { UPDATE_DROPPED_ITEMS, UPDATE_DROPPED_ID } = DragDropActions;

const initialState = {
  droppedItems: "",
  droppedID: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DROPPED_ITEMS:
      if (action.payload !== "reset") {
        return {
          ...state,
          // droppedItems: [...state.droppedItems, action.payload],
          droppedItems: action.payload,
        };
      } else {
        return {
          ...state,
          droppedItems: [],
        };
      }

    case UPDATE_DROPPED_ID:
      return {
        ...state,
        droppedID: action.payload,
      };

    default:
      return state;
  }
}
