import React from "react";
import Dropdown from "./Dropdown";
import Search from "./Search";
import PropTypes from "prop-types";

const AutoComplete = props => {
  const {
    controlClear,
    controlUpdate,
    errorMsg,
    keyName,
    label,
    lang,
    list,
    placeholder,
    required,
    searchValue
  } = props;

  switch (props.type) {
    case "dropdown":
      return (
        <Dropdown
          controlClear={controlClear}
          controlUpdate={controlUpdate}
          errorMsg={errorMsg}
          keyName={keyName}
          label={label}
          list={list}
          placeholder={placeholder}
          required={required}
          searchValue={searchValue}
        />
      );
    case "search":
      return (
        <Search
          controlClear={controlClear}
          controlUpdate={controlUpdate}
          keyName={keyName}
          lang={lang}
          list={list}
          placeholder={placeholder}
          searchValue={searchValue}
        />
      );
    default:
      return null;
  }
};

AutoComplete.defaultProps = {
  required: false
};

AutoComplete.propTypes = {
  controlClear: PropTypes.func.isRequired,
  controlUpdate: PropTypes.func.isRequired,
  errorMsg: function(props, propName, componentName) {
    if (props["type"].includes("dropdown")) {
      if (props[propName] === undefined) {
        return new Error(
          "Need string for error message. Use the 'errorMsg' prop"
        );
      }

      if (typeof props[propName] != "string") {
        return new Error("errorMsg should be a string");
      }
    }
  },
  keyName: PropTypes.string.isRequired,
  lang: function(props, propName, componentName) {
    if (props["type"].includes("search")) {
      if (props[propName] === undefined) {
        return new Error("Need string for language. Use the 'lang' prop");
      }

      if (typeof props[propName] != "string") {
        return new Error("lang should be a string");
      }
    }
  },
  list: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  searchValue: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["dropdown", "search"]).isRequired
};

export default AutoComplete;
