import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllWords } from "../../actions/HTTP-requests/getActions";
import DragContainer from "../DragAndDrop/DragContainer";
import { strToArray } from "../../utils/arrUtils";
import { findMatch } from "../../utils/searchFilterUtils";
import WordInfo from "../WordInfo";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TwoColumnList from "../TwoColumnList";

const BackBTN = (props) => (
  <button
    type="button"
    className="bold--md link-btn--light mb-50"
    onClick={props.controlReset}
  >
    <FontAwesomeIcon icon="arrow-left" /> BACK TO START LANGUAGE
  </button>
);

class FreeForm extends Component {
  state = {
    selectedWord: "",
    match: [],
    wordNotFound: false,
    arr: [],
  };

  componentDidMount() {
    this.populateArr();
  }
  populateArr = () => {
    let string = this.props.list;
    let tempArr = [];
    if (string.includes("{")) {
      let ar = string.split("{");
      ar.forEach((e) => {
        let veri = e.split("}");
        if (veri.length > 1) {
          tempArr.push(strToArray("{" + veri[0]));
          if (veri[1] !== "") {
            tempArr.push(strToArray(veri[1]));
          }
        } else if (JSON.stringify(veri) !== '[""]') {
          tempArr.push(strToArray(veri[0]));
        }
      });
    } else {
      tempArr.push(strToArray(string));
    }
    this.setState({
      arr: tempArr,
    });
  };

  handleSearchUpdate = (word) =>
    this.setState({ selectedWord: word, wordNotFound: false, match: [] });

  handleReset = () =>
    this.setState({ selectedWord: "", wordNotFound: false, match: [] });

  handleNotFound = (value) => {
    if (value === true) {
      this.props.getAllWords().then(() => {
        const match = findMatch(
          "spelling",
          this.props.allWords,
          this.state.selectedWord
        );

        return this.setState({ match, wordNotFound: value });
      });
    }
  };

  render() {
    const { match, selectedWord, wordNotFound } = this.state;
    let mobile = false;
    if (window.matchMedia("(max-width: 1024px)").matches) {
      mobile = true;
    }

    if (this.state.arr.length === 0) {
      return <div>Free Form is empty</div>;
    }

    if (wordNotFound) {
      return (
        <div>
          <BackBTN controlReset={this.handleReset} />
          {match.result.length <= 0 ? (
            <p className="bold--md mb-25">No results found</p>
          ) : (
            <React.Fragment>
              <p className="bold--md mb-25">
                Exact match not found, below are the closest matches:
              </p>
              <TwoColumnList
                list={match.result}
                controlSearchUpdate={this.handleSearchUpdate}
                bottomGrid={this.props.bottomGrid}
                key={this.props.genericToggle}
              />
            </React.Fragment>
          )}
        </div>
      );
    }

    if (mobile) {
      return (
        <div>
          {selectedWord && <BackBTN controlReset={this.handleReset} />}

          {selectedWord ? (
            <WordInfo
              selectedWord={selectedWord}
              controlSearchUpdate={this.handleSearchUpdate}
              bottomGrid={this.props.bottomGrid}
              controlNotFound={this.handleNotFound}
            />
          ) : (
            <div
              className="passage__drag-container"
              style={{ marginLeft: "20px" }}
            >
              {this.state.arr.map((item, index) => {
                if (item[0].includes("{")) {
                  return (
                    <div key={`passageFragment${index}`}>
                      <p className="assignment-list-passage-break">
                        {item.join(" ").replace("{", "")}
                      </p>
                    </div>
                  );
                }
                return (
                  /* Key value is used to repeatedly mount/unmount component to work around re-render issues */
                  <div key={`passageFragment${index}`}>
                    <DragContainer
                      controlSearchUpdate={this.handleSearchUpdate}
                      dragInto={this.props.bottomGrid}
                      list={item}
                      key={this.props.genericToggle}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    }

    return (
      <div>
        {selectedWord && <BackBTN controlReset={this.handleReset} />}

        {selectedWord ? (
          <WordInfo
            selectedWord={selectedWord}
            controlSearchUpdate={this.handleSearchUpdate}
            bottomGrid={this.props.bottomGrid}
            controlNotFound={this.handleNotFound}
          />
        ) : (
          <div className="passage__drag-container">
            {this.state.arr.map((item, index) => {
              if (item[0].includes("{")) {
                return (
                  <div key={`passageFragment${index}`}>
                    <p className="assignment-list-passage-break">
                      {item.join(" ").replace("{", "")}
                    </p>
                  </div>
                );
              }
              return (
                /* Key value is used to repeatedly mount/unmount component to work around re-render issues */
                <div key={`passageFragment${index}`}>
                  <DragContainer
                    controlSearchUpdate={this.handleSearchUpdate}
                    dragInto={this.props.bottomGrid}
                    list={item}
                    key={this.props.genericToggle}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

FreeForm.propTypes = {
  bottomGrid: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  allWords: state.get.allWords,
  genericToggle: state.genericToggle.genericToggle,
});

const mapDispatchToProps = (dispatch) => ({
  getAllWords: async () => dispatch(getAllWords()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreeForm);
