import React, { PureComponent } from "react";
import DropContainer from "../DragAndDrop/DropContainer";
import uuid from "uuid";
import PropTypes from "prop-types";

class Containers extends PureComponent {
  render() {
    const { controlGrid, positions } = this.props;
    return (
      <React.Fragment>
        {positions.map((item, index) => {
          return (
            <article className='position' key={uuid()}>
              <DropContainer
                desc={item.desc}
                gridInstance={(value) => controlGrid(value)}
                num={item.num}
                punctuation={item.punc}
                index={index}
              />
            </article>
          );
        })}
      </React.Fragment>
    );
  }
}

Containers.propTypes = {
  controlGrid: PropTypes.func.isRequired,
  positions: PropTypes.array.isRequired,
};

export default Containers;
