import React, { Component } from "react";
import { connect } from "react-redux";
import Tray from "../Tray";
import PhraseBuilder from "../PhraseBuilder";
import PropTypes from "prop-types";

class MainContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (process.env.NODE_ENV !== "development") {
      document.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
    }
  }

  render() {
    const { toggle, trayRender } = this.props;
    let pageHeading;

    switch (this.props.pageTitle) {
      case "Assignments":
        if (this.props.course !== "") {
          pageHeading = [this.props.course, ", Section ", this.props.section];
        }
        break;
      case "Directions":
        pageHeading = this.props.assignmentName;
        break;
      case "Select Course and Section":
        pageHeading = "Select Course and Section";
        break;
      case "Language Set":
        pageHeading = this.props.assignmentName;
        break;

      default:
    }

    return (
      <>
        <main id="main-container" className="main">
          {typeof this.props.combinedWords == "object" &&
            Object.keys(this.props.combinedWords).length !== 0 && (
              <PhraseBuilder
                key={this.props.phraseBuilderToggle}
                dragInto={this.props.bottomGrid}
              />
            )}

          <div className="main__test">
            <div className="main__container">
              <h1 className="main__header main__container__title">
                {pageHeading}
              </h1>
              <div className="main__container--body">
                <div className="main__body">{this.props.children}</div>
              </div>
            </div>
          </div>
          {toggle && <div className="tray__overlay" />}
          {trayRender && <Tray />}
        </main>
      </>
    );
  }
}

MainContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const mapStateToProps = (state) => ({
  bottomGrid: state.tray.bottomGrid,
  assignmentName: state.get.assignment.name,
  trayRender: state.tray.render,
  toggle: state.tray.toggle,
  phraseBuilderToggle: state.genericToggle.phraseBuilderToggle,
  combinedWords: state.combinedWords.combinedWordsList,
  course: state.mainContainer.course,
  section: state.mainContainer.section,
  pageTitle: state.mainContainer.pageTitle,
});

export default connect(mapStateToProps)(MainContainer);
