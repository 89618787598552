import React, { Component } from "react";
import { connect } from "react-redux";
import { getSpecificWord } from "../../actions/HTTP-requests/getActions";
import uuid from "uuid";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import Noun from "../PartOfSpeech/Noun";
import Pronoun from "../PartOfSpeech/Pronoun";
import Verb from "../PartOfSpeech/Verb";
import Article from "../PartOfSpeech/Article";
import Modifier from "../PartOfSpeech/Modifier";
import Preposition from "../PartOfSpeech/Preposition";
import PropTypes from "prop-types";

const POS = ({ component: Component, list, pos }) => (
  <section className="mb-50">
    <div className="bold--md">
      {`
        ${capitalizeFirstLetter(pos.toLowerCase())} 
        (${list.length} 
        ${list.length === 1 ? "entry" : "entries"})
      `}
    </div>
    {list.map((word, index) => (
      <Component key={uuid() + index} index={index} {...word} />
    ))}
  </section>
);

class WordInfo extends Component {
  state = { word: "", match: [] };

  componentDidMount() {
    const { getSpecificWord, selectedWord, controlNotFound } = this.props;
    const word = this.wordParser(selectedWord);

    if (selectedWord !== word) {
      this.setState({ word });
    }

    getSpecificWord(word).then(() => {
      const { specificWord } = this.props;
      if (Array.isArray(specificWord) && specificWord.length <= 0) {
        controlNotFound(true);
      }
      controlNotFound(false);
    });
  }

  wordParser = selectedWord => {
    let redirect = false;
    let newString = "";

    for (let i = 0; i < selectedWord.length; i++) {
      if (redirect === true && selectedWord[i] !== "]") {
        newString += selectedWord[i];
        continue;
      }

      if (selectedWord[i] === "[") {
        redirect = true;
        continue;
      }
    }

    return redirect ? newString : selectedWord;
  };

  render() {
    const { selectedWord, specificWord } = this.props;
    const { word } = this.state;
    return (
      <React.Fragment>
        <h2 className="mb-5">{word ? word : selectedWord}</h2>
        {specificWord.verb && (
          <POS component={Verb} list={specificWord.verb} pos="verb" />
        )}
        {specificWord.noun && (
          <POS component={Noun} list={specificWord.noun} pos="noun" />
        )}
        {specificWord.pronoun && (
          <POS component={Pronoun} list={specificWord.pronoun} pos="pronoun" />
        )}
        {specificWord.article && (
          <POS component={Article} list={specificWord.article} pos="article" />
        )}
        {specificWord.modifier && (
          <POS
            component={Modifier}
            list={specificWord.modifier}
            pos="modifier"
          />
        )}
        {specificWord.preposition && (
          <POS
            component={Preposition}
            list={specificWord.preposition}
            pos="preposition"
          />
        )}
      </React.Fragment>
    );
  }
}

WordInfo.propTypes = {
  controlNotFound: PropTypes.func.isRequired,
  selectedWord: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  allWords: state.get.allWords,
  bottomGrid: state.tray.bottomGrid,
  specificWord: state.get.specificWord
});

const mapDispatchToProps = dispatch => ({
  getSpecificWord: async value => dispatch(getSpecificWord(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WordInfo);
