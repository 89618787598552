import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateRenderTray,
  resetTray,
} from "../../actions/components/trayActions";
import { updatePhraseBuilderMultiSelect } from "../../actions/components/genericToggle";
import { updateSentence } from "../../actions/pages/assignmentActions";
import PageTemplate from "../../components/PageTemplate";
import AssignmentTabs from "../../components/AssignmentTabs";
import Passage from "../../components/Passage";
import VocabList from "../../components/VocabList";
import FreeForm from "../../components/FreeForm";
import Dictionary from "../../components/Dictionary"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import Submission from "../../components/Submission";
import { toSuperscript } from "../../utils/stringUtils";

class ConstructSentence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignmentType: "",
      copyBTNClicked: false,
      submit: false,
    };
    this.handler = this.handler.bind(this);
  }

  handler = () => {
    this.setState({
      submit: false,
    });
  };

  sentenceDisplay = React.createRef();
  copyBTN = React.createRef();
  componentDidMount() {
    const { assignment, updateRenderTray } = this.props;

    switch (assignment.type) {
      default: this.setState({
        assignmentType: "Passage"
      });
      break;
      case "vocab list":
        this.setState({
          assignmentType: "Vocab"
        });
        break;

      case "passage":
        this.setState({
          assignmentType: "Passage"
        });
        break;
      case "free form":
        this.setState({
          assignmentType: "Start Language"
        }); 
        break;
    }
    updateRenderTray(true);
  }

  handleCopyToClipboard = () => {
    const positionDescriptions = [];
    const userPositions = [];
    const positions = this.props.positions;
    const sentenceValues = Object.values(this.props.sentence);
    let objLength = Object.keys(positions).length;
    let sentenceObjectLength = Object.keys(this.props.sentence).length;

    for (let i = 0; i < objLength; i++) {
      positionDescriptions.push(positions[i].desc);
    }

    for (let i = 0; i < sentenceObjectLength; i++) {
      userPositions.push(sentenceValues[i].text.join(' '));
    }

    let textBody = '';

    const positionsLength = positionDescriptions.length;
    for(let i = 0; i < positionsLength; i++){
        textBody += "Position";
        textBody += i + 1;
        textBody += " : ";
        textBody += positionDescriptions[i];
        textBody += '\n';
        textBody += '-';
        textBody += userPositions[i];
        textBody += '\n';
        textBody += '\n';
    }
    textBody += '\n';

    const textArea = document.createElement("textarea");
    textArea.value = textBody;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    this.copyBTN.current.focus();
    window.scrollTo(0, 0);

    this.setState(
      {
        copyBTNClicked: true,
      },
      () => {
        setTimeout(() => this.setState({ copyBTNClicked: false }), 700);
      }
    );
  };

  handleDeleteAll = () => {
    const { bottomGrid } = this.props;

    for (let i = 0; i < bottomGrid.length; i++) {
      // length is being defined with the -1 to account for the dummy item that
      // exists in all positions
      const length = bottomGrid[i]._items.length - 1;
      // If grid does not contain added objects then exit iteration
      // if (length <= 1) continue;

      const { sentence, updateSentence } = this.props;
      const lastItem = bottomGrid[i]._items[length]._element.innerText;
      const gridID = bottomGrid[i]._id;
      const arr = [];
      let sentenceID;

      for (let j = 0; j < length; j++) {
        arr.push(bottomGrid[i]._items[j]);
      }

      // find sentence id for current bottomGrid item
      for (let item in sentence) {
        if (sentence[item].id === gridID) {
          sentenceID = item;
          break;
        }
      }
      updateSentence(sentenceID, [lastItem], gridID);
      bottomGrid[i].remove(arr, { removeElements: true });
    }
  };

  handleRenderSentence = () => {
    const { positions, sentence } = this.props;
    let result = "";
    let punctuationLength = 0;

    positions.forEach((position) => {
      if (position.num in sentence) {
        return sentence[position.num].text.map((item, id) => {
          if (item === "." || item === ",") {
            punctuationLength = 1;
            result += item;
          } else {
            result += " " + item;
          }

          if (
            id === sentence[position.num].text.length - 1 &&
            sentence[position.num].text.length > punctuationLength
          ) {
            result += " |" + toSuperscript(position.num.toString()) + " ";
          }
          return result;
        });
      }
    });
    return result;
  };

  handleSubmitAssignment = () => {
    this.setState({ submit: true });
  };

  render() {
    const { assignmentType } = this.state;
    const { assignment, bottomGrid } = this.props;
    const sentence = this.handleRenderSentence();
    const phrase = "  Build Your Phrase";
    const active = this.props.phraseBuilderMultiSelect
      ? "phraseBuilderMultiSelectButton__active"
      : "";

    if (this.props.isPractice === "practice") {
      return (
        <PageTemplate title="Language Set">
          <div className="construct-sentence">
            <div className="construct-sentence__sentence-overview-container">
              <p className="construct-sentence__sentence-label">
                Your Language by Position:
              </p>
              <button
                className="filter-btn--applied-clear-all bold--sm"
                onClick={this.handleDeleteAll}
              >
                <FontAwesomeIcon className="mr-10" icon="times-circle" />
                Clear All
              </button>
            </div>
            <div className="construct-sentence__sentence-container">
              <div
                ref={this.sentenceDisplay}
                className="construct-sentence__sentence bold--md"
              >
                {sentence}
              </div>
              <button
                ref={this.copyBTN}
                className={`
              construct-sentence__copy-btn
                      ${
                        this.state.copyBTNClicked
                          ? "construct-sentence__copy-btn--active"
                          : ""
                      }
                    `}
                onClick={this.handleCopyToClipboard}
              >
                {this.state.copyBTNClicked ? (
                  <FontAwesomeIcon icon="check" />
                ) : (
                  <FontAwesomeIcon icon="clipboard" />
                )}
              </button>
            </div>
            <button
              onClick={this.props.updatePhraseBuilderMultiSelect}
              className={`phraseBuilderMultiSelectButton ${active}`}
            >
              <FontAwesomeIcon icon={faPuzzlePiece} />
              {phrase}
            </button>
          </div>

          {bottomGrid ? (
            <AssignmentTabs tabHeaders={[assignmentType, "Dictionary"]}>
              <React.Fragment>
                {assignmentType === "Vocab" ? (
                  <VocabList bottomGrid={bottomGrid} />
                ) : (
                  null
                )
                }
                {assignmentType === "Passage" ? (
                  <Passage bottomGrid={bottomGrid} list={assignment.passage} />
                ) : (
                  null
                )
                }
                {assignmentType === "Start Language" ? (
                  <FreeForm bottomGrid={bottomGrid} list={assignment.passage} />
                ) : (
                  null
                )
                }
              </React.Fragment>
              <Dictionary bottomGrid={bottomGrid} /> 
            </AssignmentTabs>
          ) : (
            <div>Error: unable to load assignment</div>
          )}
        </PageTemplate>
      );
    } else {
      return (
        <>
          <PageTemplate title="Language Set">
            <div className="construct-sentence">
              <div className="construct-sentence__sentence-overview-container">
                <p className="construct-sentence__sentence-label">
                  Your Answer:
                </p>
                <button
                  className="yourAnswerClearBtn bold--sm"
                  onClick={this.handleDeleteAll}
                >
                  <FontAwesomeIcon className="mr-10" icon="times-circle" />
                  Clear
                </button>
              </div>
              <div className="construct-sentence__sentence-container">
                <div
                  ref={this.sentenceDisplay}
                  className="construct-sentence__sentence bold--md"
                >
                  <span>{sentence}</span>
                </div>
                <button
                  ref={this.copyBTN}
                  className={`
              construct-sentence__copy-btn
                      ${
                        this.state.copyBTNClicked
                          ? "construct-sentence__copy-btn--active"
                          : ""
                      }
                    `}
                  onClick={this.handleSubmitAssignment}
                >
                  {this.state.copyBTNClicked ? (
                    <FontAwesomeIcon icon="check" />
                  ) : (
                    <FontAwesomeIcon icon={faPaperPlane} />
                  )}
                </button>
              </div>

              <button
                onClick={this.props.updatePhraseBuilderMultiSelect}
                className={`phraseBuilderMultiSelectButton ${active}`}
              >
                <FontAwesomeIcon icon={faPuzzlePiece} />
                {phrase}
              </button>
            </div>

            {bottomGrid ? (
              <AssignmentTabs tabHeaders={[assignmentType, "Dictionary"]}> 
                <>
                  {assignmentType === "Vocab" ? (
                    <VocabList bottomGrid={bottomGrid} />
                  ) : (
                    null
                  )}
                  {assignmentType === "Passage" ? (
                    <Passage
                    bottomGrid={bottomGrid}
                    list={assignment.passage}
                  />
                  ) : (
                    null
                  )}
                  {assignmentType === "Start Language" ? (
                  <FreeForm bottomGrid={bottomGrid} list={assignment.passage} />
                ) : (
                  null
                )
                }
                </>
                <Dictionary bottomGrid={bottomGrid} />
              </AssignmentTabs>
            ) : (
              <div>Error: unable to load assignment</div>
            )}
            <Submission
              submit={this.state.submit}
              updateSubmit={this.handler}
            />
          </PageTemplate>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  assignment: state.get.assignment,
  bottomGrid: state.tray.bottomGrid,
  assignmentStore: state.assignment,
  positions: state.assignment.positionsArr,
  sentence: state.assignment.sentence,
  whichCourse: state.route.courseID,
  assignmentName: state.get.assignment.name,
  genericToggle: state.genericToggle.genericToggle,
  phraseBuilderMultiSelect: state.genericToggle.phraseBuilderMultiSelect,
  isPractice: state.get.assignment.grade,
  passage: state.get.assignment.passage,
});

const mapDispatchToProps = (dispatch) => ({
  updateRenderTray: (value) => dispatch(updateRenderTray(value)),
  updateSentence: (sentenceID, arr, gridID) =>
    dispatch(updateSentence(sentenceID, arr, gridID)),
  resetTray: () => dispatch(resetTray()),
  updatePhraseBuilderMultiSelect: () =>
    dispatch(updatePhraseBuilderMultiSelect()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConstructSentence);
