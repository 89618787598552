import React from "react";
import DragList from "./DragList";
import List from "./List";
import PropTypes from "prop-types";

const DataList = props => {
  switch (props.type) {
    case "drag":
      return <DragList {...props} />;
    default:
      return <List {...props} />;
  }
};

DataList.defaultProps = {
  type: "list"
};

DataList.propTypes = {
  lang: PropTypes.string,
  list: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["drag", "list"])
};

export default DataList;
