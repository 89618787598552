import React, { Component } from "react";
import { connect } from "react-redux";
import { updateTitle } from "../../actions/components/headerActions";
import PropTypes from "prop-types";

class PageTemplate extends Component {
  componentDidMount() {
    const { title, updateTitle } = this.props;
    document.getElementById("main-root").focus();
    document.title = `${title} - Grammar Cruncher`;
    updateTitle(title);
  }

  componentWillUnmount() {
    this.props.updateTitle("");
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

const mapDispatchToProps = dispatch => ({
  updateTitle: value => dispatch(updateTitle(value))
});

PageTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  title: PropTypes.string.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(PageTemplate);
