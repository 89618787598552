import { HeaderActions } from "../../actions/types";
const { UPDATE_TITLE, UPDATE_COURSE, UPDATE_SECTION } = HeaderActions;

const initialState = {
  pageTitle: "",
  course: "",
  section: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_TITLE:
      return {
        ...state,
        pageTitle: action.payload,
      };
    case UPDATE_COURSE:
      return {
        ...state,
        course: action.payload,
      };
    case UPDATE_SECTION:
      return {
        ...state,
        section: action.payload,
      };
    default:
      return state;
  }
}
