import { TrayActions } from "../../actions/types";
const {
  UPDATE_RENDER_TRAY,
  UPDATE_TRAY_TOGGLE,
  UPDATE_BOTTOM_GRID,
  UPDATE_DELETE_GRID,
  RESET_TRAY
} = TrayActions;

const initialState = {
  render: false,
  toggle: false,
  bottomGrid: [],
  deleteGrid: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_RENDER_TRAY:
      return {
        ...state,
        render: action.payload
      };
    case UPDATE_TRAY_TOGGLE:
      return {
        ...state,
        toggle: action.payload
      };
    case UPDATE_BOTTOM_GRID:
      return {
        ...state,
        bottomGrid: [...state.bottomGrid, action.payload]
      };
    case UPDATE_DELETE_GRID:
      return {
        ...state,
        deleteGrid: action.payload
      };
    case RESET_TRAY:
      return {
        ...state,
        render: false,
        toggle: false,
        bottomGrid: null,
        deleteGrid: null
      };
    default:
      return state;
  }
}
