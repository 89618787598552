import {
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faBars,
  faBook,
  faCog,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faClipboard,
  faEdit,
  faExclamationTriangle,
  faEye,
  faGraduationCap,
  faGripVertical,
  faHome,
  faInbox,
  faInfoCircle,
  faPencilAlt,
  faPlus,
  faSearch,
  faSignOutAlt,
  faSitemap,
  faSlidersH,
  faSort,
  faSortDown,
  faSortUp,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUsers
} from "@fortawesome/free-solid-svg-icons";

export default [
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faBars,
  faBook,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faClipboard,
  faCog,
  faEdit,
  faExclamationTriangle,
  faEye,
  faGraduationCap,
  faGripVertical,
  faHome,
  faInbox,
  faInfoCircle,
  faPencilAlt,
  faPlus,
  faSearch,
  faSignOutAlt,
  faSitemap,
  faSlidersH,
  faSort,
  faSortDown,
  faSortUp,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUsers
];
