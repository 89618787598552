import { GetActions } from "../../actions/types";
const {
  GET_ALL_ASSIGNMENTS,
  GET_SECTION_ASSIGNMENTS,
  GET_ALL_WORDS,
  GET_COURSES,
  GET_SPECIFIC_ASSIGNMENT,
  GET_SPECIFIC_WORD,
  SESSION_EXPIRED
} = GetActions;

const initialState = {
  allAssignments: [],
  allSectionAssignments: [],
  allCourses: [],
  allWords: [],
  assignment: [],
  sessionExpired: false,
  specificWord: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ASSIGNMENTS:
      return {
        ...state,
        allAssignments: action.payload,
        sessionExpired: false
      };
    case GET_SECTION_ASSIGNMENTS:
      return {
        ...state,
        sectionAssignments: action.payload,
        sessionExpired: false
      };
    case GET_ALL_WORDS:
      return {
        ...state,
        allWords: action.payload,
        sessionExpired: false
      };
    case GET_COURSES:
      return {
        ...state,
        allCourses: action.payload,
        sessionExpired: false
      };
    case GET_SPECIFIC_ASSIGNMENT:
      return {
        ...state,
        assignment: action.payload,
        sessionExpired: false
      };
    case GET_SPECIFIC_WORD:
      return {
        ...state,
        sessionExpired: false,
        specificWord: action.payload
      };
    case SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: true
      }
    default:
      return state;
  }
}
