import { HeaderActions } from "../types";
const { UPDATE_TITLE, UPDATE_COURSE, UPDATE_SECTION } = HeaderActions;

export const updateTitle = (payload) => ({
  type: UPDATE_TITLE,
  payload,
});

export const updateCourse = (payload) => ({
  type: UPDATE_COURSE,
  payload,
});

export const updateSection = (payload) => ({
  type: UPDATE_SECTION,
  payload,
});
