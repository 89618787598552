import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlphabeticalFilter from "./AlphabeticalFilter";
import AppliedFilters from "./AppliedFilters";
import AutoComplete from "../AutoComplete";
import POSFilter from "./POSFilter";

class SearchFilterView extends Component {
  state = {
    toggleSpecificFilter: ""
  };

  clearSearch = () => {
    this.props.updateSearchValue("");
    return this.props.controlSearch(null, true);
  };

  toggleSpecificFilter = value => {
    return this.setState({
      toggleSpecificFilter:
        value === this.state.toggleSpecificFilter ? "" : value
    });
  };

  render() {
    const { toggleSpecificFilter } = this.state;
    const {
      controlFilter,
      controlSearch,
      keyName,
      lang,
      list,
      placeholder,
      searchValue,
      selectedFilters,
      toggleFilter,
      updateSearchValue,
      updateToggleFilter
    } = this.props;

    const mobile = window.matchMedia("(max-width: 1024px)").matches;

    const desktopBTN = (
      <div className="search-bar--desktop mr-25">
        <button
          aria-label="Filter menu"
          className={`
            bold--md
            ${toggleFilter ? "secondary-btn--selected" : "secondary-btn"}
          `}
          onClick={() => updateToggleFilter(!toggleFilter)}
        >
          {mobile ? <FontAwesomeIcon icon="sliders-h" /> : "Filters"}
        </button>
      </div>
    );

    const mobileBTN = (
      <div className="search-bar--mobile mt-25">
        <button
          aria-label="Filter menu"
          className={`
            bold--md
            block
            full-width
            ${toggleFilter ? "secondary-btn--selected" : "secondary-btn"}
          `}
          onClick={() => updateToggleFilter(!toggleFilter)}
        >
          <FontAwesomeIcon className="mr-15" icon="sliders-h" />
          Filters
        </button>
      </div>
    );

    return (
      <section>
        <div className="card--dark search-bar__container shadow--small">
          <div className="flex">
            {desktopBTN}
            <div className="search-bar__input">
              <AutoComplete
                controlClear={this.clearSearch}
                controlUpdate={updateSearchValue}
                keyName={keyName}
                lang={lang}
                list={list}
                placeholder={placeholder}
                searchValue={searchValue}
                type="search"
              />
            </div>
            <div>
              <button
                aria-label="Search"
                className="primary-btn--search bold--md"
                onClick={controlSearch}
              >
                {mobile ? <FontAwesomeIcon icon="search" /> : "Search"}
              </button>
            </div>
          </div>
          {mobileBTN}
        </div>
        <AppliedFilters
          selectedFilters={selectedFilters}
          controlFilter={controlFilter}
        />
        {toggleFilter && (
          <div className="filter mt-25">
            <div className="filter__name-container">
              <p className="bold--md filter__name">Filters:</p>
              <button
                aria-label="Close filter menu"
                className="filter__close"
                onClick={() => updateToggleFilter(!toggleFilter)}
              >
                <FontAwesomeIcon icon="times" />
              </button>
            </div>
            <dl role="presentation">
              {selectedFilters.map((item, index) => {
                switch (item) {
                  case "AlphabeticalFilter":
                    return (
                      <AlphabeticalFilter
                        key={`alphabeticalfilter-${index}`}
                        controlFilter={controlFilter}
                        controlFilterToggle={this.toggleSpecificFilter}
                        toggleValue={toggleSpecificFilter === item}
                      />
                    );
                  case "POSFilter":
                    return (
                      <POSFilter
                        key={`posfilter-${index}`}
                        controlFilter={controlFilter}
                        controlFilterToggle={this.toggleSpecificFilter}
                        toggleValue={toggleSpecificFilter === item}
                      />
                    );

                  default:
                    return undefined;
                }
              })}
            </dl>
          </div>
        )}
      </section>
    );
  }
}

export default SearchFilterView;
