import React, { Component } from "react";
import UFLogo from "../../assets/images/UF-Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <a
          className='footer__report-btn bold--md mr-25'
          href='https://form.jotform.com/92344538423155'
          target='_blank'
          rel='noopener noreferrer'>
          <FontAwesomeIcon className='mr-10' icon='exclamation-triangle' />
          Report Bug
        </a>
        <img
          className='footer__logo'
          src={UFLogo}
          alt='University of Florida Logo'
        />
      </footer>
    );
  }
}

export default Footer;
