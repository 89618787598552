import React, { Component } from "react";
import { connect } from "react-redux";
import {
  clearFilteredList,
  resetSearchFilter,
  updateFilteredList
} from "../../actions/components/searchFilterActions";
import { alphabeticalFilter, findMatch } from "../../utils/searchFilterUtils";
import { compareArr } from "../../utils/arrUtils";
import SearchFilterView from "./SearchFilterView";
import PropTypes from "prop-types";

class SearchFilter extends Component {
  componentWillUnmount() {
    this.props.resetSearchFilter();
  }

  handleSearch = (e, reset = false) => {
    const {
      keyName,
      list,
      resetSearchFilter,
      searchValue,
      updateMatchedObj,
      updateFilteredList,
      updateToggleFilter
    } = this.props;

    if (!searchValue.length || reset) {
      updateFilteredList(list);
      return updateMatchedObj({}, false);
    }

    const searchObj = findMatch(keyName, list, searchValue);

    resetSearchFilter();
    updateToggleFilter(false);

    if (searchObj.match) {
      updateFilteredList([searchObj.result]);
      return updateMatchedObj(searchObj.result, true);
    } else {
      updateFilteredList(searchObj.result);
      return updateMatchedObj({}, false);
    }
  };

  handleFilter = () => {
    const {
      alphabeticalKey,
      list,
      posKey,
      selectedFilters,
      updateFilteredList,
      updateMatchedObj,
      updateSearchValue
    } = this.props;
    let tempList = list;

    selectedFilters.forEach(filter => {
      const appliedFilters = this.props[filter].appliedFilters;
      if (appliedFilters.length) {
        switch (filter) {
          case "POSFilter":
            return (tempList = compareArr(appliedFilters, tempList, posKey));
          case "AlphabeticalFilter":
            return (tempList = alphabeticalFilter(
              appliedFilters[0],
              tempList,
              alphabeticalKey
            ));
          default:
            return null;
        }
      }
    });

    updateSearchValue("");
    updateMatchedObj({}, false);
    return updateFilteredList(tempList);
  };

  render() {
    return (
      <SearchFilterView
        controlFilter={this.handleFilter}
        controlSearch={this.handleSearch}
        {...this.props}
      />
    );
  }
}

SearchFilter.defaultProps = {
  lang: "en"
};

SearchFilter.propTypes = {
  alphabeticalKey: function(props, propName, componentName) {
    if (props["selectedFilters"].includes("AlphabeticalFilter")) {
      if (props[propName] === undefined) {
        return new Error(
          "Need object key value for Alphabetical filter. Use 'alphabeticalKey' prop."
        );
      }

      if (typeof props[propName] != "string") {
        return new Error(
          "Object key value for alphabeticalKey should be a String"
        );
      }
    }
  },
  keyName: PropTypes.string.isRequired,
  lang: PropTypes.string,
  list: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  posKey: function(props, propName, componentName) {
    if (props["selectedFilters"].includes("POSFilter")) {
      if (props[propName] === undefined) {
        return new Error(
          "Need object key value for Part of Speech filter. Use 'posKey' prop."
        );
      }

      if (typeof props[propName] != "string") {
        return new Error("Object key value for posKey should be a String");
      }
    }
  },
  searchValue: PropTypes.string.isRequired,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.oneOf(["AlphabeticalFilter", "POSFilter"])
  ).isRequired,
  toggleFilter: PropTypes.bool.isRequired,
  updateMatchedObj: PropTypes.func.isRequired,
  updateSearchValue: PropTypes.func.isRequired,
  updateToggleFilter: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  AlphabeticalFilter: state.searchFilter.AlphabeticalFilter,
  POSFilter: state.searchFilter.POSFilter
});

const mapDispatchToProps = dispatch => ({
  clearFilteredList: () => dispatch(clearFilteredList()),
  resetSearchFilter: () => dispatch(resetSearchFilter()),
  updateFilteredList: value => dispatch(updateFilteredList(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFilter);
