import React, { Component } from "react";
import { connect } from "react-redux";
import { updateIndex } from "../../actions/components/tabListActions";
import uuid from "uuid";
import PropTypes from "prop-types";

class TabList extends Component {
  state = {};

  componentDidMount() {
    const { children, selectedIndex } = this.props;
    const list = [];
    document.addEventListener("keydown", this.handleTab);
    children.map((item, index) =>
      list.push({
        tabID: uuid(),
        sectionID: uuid(),
        selected: index === selectedIndex ? true : false,
        tabIndex: index === selectedIndex ? "0" : "-1"
      })
    );
    this.setState({ list });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleTab);
  }

  handleTab = (e) => {
    const key = e.which || e.keyCode;
    const direction = this.handleDirection(key);

    if (direction) {
      this.state.list.map((item, index) => {
        if (item.tabID === document.activeElement.id) {
          return this.handleKeyNav(index, direction);
        }
        return undefined;
      });
    }
  };

  handleDirection = (key) => {
    switch (key) {
      case 37:
        return -1;
      case 39:
        return 1;
      case 40:
        return 1;
      case 38:
        return -1;
      default:
        return false;
    }
  };

  handleKeyNav = (index, direction) => {
    const { updateIndex } = this.props;
    const newIndex = this.handleIndex(index, direction);

    return updateIndex(newIndex).then(() => this.handleShowSection());
  };

  handleIndex = (index, direction) => {
    const { list } = this.state;

    if (list.length - 1 < index + direction) {
      return index;
    } else if (0 > index + direction) {
      return index;
    } else {
      return index + direction;
    }
  };

  handleShowSection = () => {
    const { list } = this.state;
    const { selectedIndex } = this.props;
    const newActiveEl = document.getElementById(list[selectedIndex].tabID);
    newActiveEl.focus();

    this.setState({
      list: list.map((item, i) => {
        if (i === selectedIndex) {
          return {
            ...item,
            selected: true,
            tabIndex: "0"
          };
        }

        return {
          ...item,
          selected: false,
          tabIndex: "-1"
        };
      })
    });
  };

  render() {
    const { children, tabHeaders, updateIndex } = this.props;
    const { list } = this.state;

    return (
      <div className='shadow--small tablist'>
        <ul role='tablist' className='tablist__header'>
          {children.map(
            (item, index) =>
              list && (
                <li
                  role='presentation'
                  key={list[index].tabID}
                  className='tablist__list-item'>
                  <button
                    type='button'
                    id={list[index].tabID}
                    role='tab'
                    aria-selected={list[index].selected}
                    tabIndex={list[index].tabIndex}
                    onClick={() =>
                      updateIndex(index).then(() => this.handleShowSection())
                    }
                    className='tablist__btn'>
                    {tabHeaders[index]}
                  </button>
                </li>
              )
          )}
        </ul>
        {list &&
          this.props.children.map((item, index) => {
            if (list[index].selected) {
              return (
                <section
                  id={list[index].sectionID}
                  role='tabpanel'
                  aria-labelledby={list[index].tabID}
                  className='tablist__section'
                  key={list[index].sectionID}>
                  {item}
                </section>
              );
            }
            return <div key={index}></div>;
          })}
      </div>
    );
  }
}

TabList.propTypes = {
  tabHeaders: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  selectedIndex: state.tabList.selectedIndex
});

const mapDispatchToProps = (dispatch) => ({
  updateIndex: async (value) => dispatch(updateIndex(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(TabList);
