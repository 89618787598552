import React from "react";
import uuid from "uuid";
import { urlValidator } from "../../utils/httpUtils";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import DataList from "../DataList";
import PropTypes from "prop-types";

const Article = props => {
  const noLinks = props.link
    .map(item => {
      if (item.text === "" && item.url === "") return false;
      return undefined;
    })
    .includes(false);

  let emptySingularList = props.singular.length === undefined ? false : true;
  let emptyPluralList = props.plural.length === undefined ? false : true;
  const caseOutput = list => {
    const obj = {};

    for (let key in list) {
      if (list[key]) {
        obj[capitalizeFirstLetter(key)] = list[key];
      }
    }

    return obj;
  };

  return (
    <section className="card block shadow--small mt-25">
      <h3 className="bold--lg">{`${props.index + 1}: ${props.spelling}`}</h3>
      <div className="mt-25">
        <table className="datalist mb-50">
          <tbody>
            <tr>
              <th
                className="datalist__header bold--md pl-25 datalist__header--first"
                scope="row"
              >
                Definition:
              </th>
              <td className="datalist__item pl-25 pr-25 datalist__item--first">
                {props.definition}
              </td>
            </tr>

            {!noLinks && (
              <tr>
                <th
                  className="datalist__header bold--md pl-25 pr-25"
                  scope="row"
                >
                  Definition Link(s):
                </th>
                <td className="datalist__item pl-25 pr-25">
                  <ul className="mt-5">
                    {props.link.map((item, index) => (
                      <li key={uuid() + index}>
                        <a
                          className="link-btn--light"
                          href={urlValidator(item.url)}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {item.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            )}

            <tr>
              <th className="datalist__header bold--md pl-25 pr-25" scope="row">
                Definite:
              </th>
              <td className="datalist__item pl-25 pr-25">
                {Number(props.definite) === 0 ? "No" : "Yes"}
              </td>
            </tr>

            <tr>
              <th className="datalist__header bold--md pl-25 pr-25" scope="row">
                Gender:
              </th>
              <td className="datalist__item pl-25 pr-25">{props.gender}</td>
            </tr>

            <tr>
              <th
                className="datalist__header bold--md pl-25 pr-25 datalist__header--last"
                scope="row"
              >
                Number:
              </th>
              <td className="datalist__item pl-25 pr-25 datalist__item--last">
                {props.number}
              </td>
            </tr>
          </tbody>
        </table>

        {!emptySingularList && (
          <div className="mb-50">
            <h4 className="bold--lg mb-5">Case Singular:</h4>
            <DataList lang="de" list={caseOutput(props.singular)} type="drag" />
          </div>
        )}

        {!emptyPluralList && (
          <div className="mb-50">
            <h4 className="bold--lg mb-5">Case Plural:</h4>
            <DataList lang="de" list={caseOutput(props.plural)} type="drag" />
          </div>
        )}
      </div>
    </section>
  );
};

Article.propTypes = {
  index: PropTypes.number.isRequired
};

export default Article;
