import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import RouteContainer from "./RouteContainer";

// ############### LAYOUTS ###############
import MainLayout from "../layout/MainLayout";
import NoLayout from "../layout/NoLayout";

// ############### PAGES ###############
import Assignment from "../pages/Assignment/index";
import Assignments from "../pages/Assignments";
import SelectCourse from "../pages/SelectCourse";
import NotFound from "../pages/NotFound";

const Index = () => (
  <Router>
    <Switch>
      <RouteContainer
        exact
        path="/"
        layout={MainLayout}
        component={SelectCourse}
      />
      <RouteContainer
        exact
        path="/assignments"
        layout={MainLayout}
        component={Assignments}
      />
      <RouteContainer
        exact
        path="/assignment"
        layout={MainLayout}
        component={Assignment}
      />
      <RouteContainer component={NotFound} layout={NoLayout} />
    </Switch>
  </Router>
);

export default Index;
