import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { filterList, updateSearch } from "../../utils/autoCompleteUtils";
import PropTypes from "prop-types";

const Dropdown = props => {
  const {
    controlClear,
    controlUpdate,
    errorMsg,
    keyName,
    label,
    list,
    placeholder,
    required,
    searchValue
  } = props;

  const [suggestions, setSuggestions] = useState([]);

  const config = {
    getSuggestions: value => filterList(value, list, keyName),
    handleClearSuggestions: () => setSuggestions([]),
    handleFetchSuggestion: ({ value }) =>
      setSuggestions(config.getSuggestions(value, list)),
    handleRenderSuggestion: suggestion => <div>{suggestion[keyName]}</div>,
    handleSuggestionValue: suggestion => suggestion,
    shouldRenderSuggestions: () => true
  };

  const inputProps = {
    value: searchValue,
    onChange: (event, { newValue }) => {
      event.preventDefault();
      return updateSearch(event, newValue, list, controlUpdate, keyName);
    },
    type: "text",
    placeholder: placeholder,
    required
  };

  return (
    <div className="auto-complete--dropdown">
      <label>
        <div className="bold--md mb-5">
          {required && <span className="color-red-50">* </span>}
          {label}
        </div>
        {!list.length ? (
          <div className="reg--sm">{errorMsg}</div>
        ) : (
          <React.Fragment>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={config.handleFetchSuggestion}
              onSuggestionsClearRequested={config.handleClearSuggestions}
              getSuggestionValue={config.handleSuggestionValue}
              shouldRenderSuggestions={config.shouldRenderSuggestions}
              renderSuggestion={config.handleRenderSuggestion}
              inputProps={inputProps}
              focusInputOnSuggestionClick={false}
            />
            {searchValue && (
              <button
                aria-label="clear selected option"
                className="auto-complete__clear-btn"
                onClick={controlClear}
              >
                <FontAwesomeIcon
                  className="color-black-60"
                  icon="times-circle"
                />
              </button>
            )}
          </React.Fragment>
        )}
      </label>
    </div>
  );
};

Dropdown.propTypes = {
  controlClear: PropTypes.func.isRequired,
  controlUpdate: PropTypes.func.isRequired,
  errorMsg: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  searchValue: PropTypes.string.isRequired
};

export default Dropdown;
