import * as Fuse from "fuse.js";
import moment from "moment";
import { isEmpty } from "./objUtils";

export const alphabeticalFilter = (character, arr, keyName) => {
  const charValue = character.trim().toLowerCase();
  const result = [];

  for (let i = 0; i < arr.length; i++) {
    const arrItem = keyName
      ? arr[i][keyName].charAt(0).toLowerCase()
      : arr[i].charAt(0).toLowerCase();

    if (arrItem === charValue) {
      result.push(arr[i]);
    }
  }

  return result;
};

export const classFilter = (
  courseSearchObj,
  moduleSearchObj,
  sectionSearchObj,
  tempList,
  vocabFilter
) => {
  if (vocabFilter) {
    return tempList.filter(item => {
      if (item.module.length === 0) {
        return false;
      }

      let result = false;
      for (let i = 0; i < item.module.length; i++) {
        if (item.module[i].courseid !== courseSearchObj.id) {
          continue;
        } else {
          result = isEmpty(moduleSearchObj)
            ? true
            : moduleSearchObj.id === item.module[i].id;
        }
      }

      return result;
    });
  }

  return tempList.filter(item => {
    let result = true;
    if (!isEmpty(courseSearchObj)) {
      if (courseSearchObj.id !== item.course.id) result = false;
    }

    if (!isEmpty(moduleSearchObj) && result !== false) {
      if (moduleSearchObj.id !== item.module.id) result = false;
    }

    if (!isEmpty(sectionSearchObj) && result !== false) {
      if (sectionSearchObj.id !== item.section.id) result = false;
    }

    return result;
  });
};

// will need the following args -> filterEndDate, filterStartDate, end and start date key, templist
export const dateFilter = (
  startDateKey,
  endDateKey,
  filterStartDate,
  filterEndDate,
  tempList
) => {
  return tempList.filter(item => {
    const start = moment.unix(item[startDateKey] / 1000);
    const due = moment.unix(item[endDateKey] / 1000);
    const afterStart = filterStartDate
      ? start.isSameOrAfter(filterStartDate)
      : true;
    const beforeDue = filterEndDate ? due.isSameOrBefore(filterEndDate) : true;

    return afterStart && beforeDue;
  });
};

export const findMatch = (keyName, list, searchValue) => {
  const obj = {};
  const options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    minMatchCharLength: 1,
    keys: [keyName]
  };
  const fuse = new Fuse(list, options);

  obj.result = fuse.search(searchValue);

  if (obj.result.length !== 0 && obj.result[0][keyName] === searchValue) {
    obj.match = true;
    obj.result = obj.result[0];
  } else {
    obj.match = false;
  }

  return obj;
};

export const publishCheck = (list, keyName, filter) => {
  return list.filter(item => {
    switch (filter[0]) {
      case "Not Published":
        return Number(item[keyName]) === 0;
      case "Published":
        return Number(item[keyName]) === 1;
      default:
        return null;
    }
  });
};

export const typeCheck = (list, keyName, filter) => {
  return list.filter(item => item[keyName] === filter[0]);
};
