// REACT AND ROUTER
import React from "react";
import uuid from "uuid";
import { urlValidator } from "../../utils/httpUtils";
import DataList from "../DataList";
import PropTypes from "prop-types";

const Verb = props => {
  const noLinks = props.link
    .map(item => {
      if (item.text === "" && item.url === "") return false;
      return undefined;
    })
    .includes(false);

    /* Requested changes to verb pronouns */
    /* Present tense changes */
    const initialPresData = props.present;

    const transformPresSingular = (data) => {
      const arr = Object.entries(initialPresData.singular);
        
        let newProperty;
        const newObject = {}

        arr.forEach(entry => {
          switch (entry[0]) {
            case "1st":
              newProperty = "ich";
              break;
            case "2nd": 
              newProperty = "du";
              break;
            default:
              newProperty = "er, es, sie";
          }

          newObject[newProperty] = entry[1]
        })

        return newObject;
    }
    const propPresSingular = transformPresSingular(initialPresData);

    const transformPresPlural = (data) => {
      const arr = Object.entries(initialPresData.plural);
        
        let newProperty;
        const newObject = {}

        arr.forEach(entry => {
          switch (entry[0]) {
            case "1st":
              newProperty = "wir";
              break;
            case "2nd": 
              newProperty = "ihr";
              break;
            default:
              newProperty = "sie, Sie";
          }

          newObject[newProperty] = entry[1]
        })

        return newObject;
    }
    const propPresPlural = transformPresPlural(initialPresData);

    /* Past Tense */

    const initialPastData = props["simple past"];

    const transformPastSingular = (data) => {
      const arr = Object.entries(initialPastData.singular);
        
        let newProperty;
        const newObject = {}

        arr.forEach(entry => {
          switch (entry[0]) {
            case "1st":
              newProperty = "ich";
              break;
            case "2nd": 
              newProperty = "du";
              break;
            default:
              newProperty = "er, es, sie";
          }

          newObject[newProperty] = entry[1]
        })

        return newObject;
    }
    const propPastSingular = transformPastSingular(initialPastData);

    const transformPastPlural = (data) => {
      const arr = Object.entries(initialPastData.plural);
        
        let newProperty;
        const newObject = {}

        arr.forEach(entry => {
          switch (entry[0]) {
            case "1st":
              newProperty = "wir";
              break;
            case "2nd": 
              newProperty = "ihr";
              break;
            default:
              newProperty = "sie, Sie";
          }

          newObject[newProperty] = entry[1]
        })

        return newObject;
    }
    const propPastPlural = transformPastPlural(initialPastData);


    /* End verb pronouns */


  return (
    <section className="card block shadow--small mt-25">
      <h3 className="bold--lg">{`${props.index + 1}.`}</h3>

      <div className="mt-25">
        <table className="datalist mb-50">
          <tbody>
            <tr>
              <th
                className="datalist__header bold--md pl-25 datalist__header--first"
                scope="row"
              >
                Definition:
              </th>
              <td className="datalist__item pl-25 pr-25 datalist__item--first">
                {props.definition}
              </td>
            </tr>

            {!noLinks && (
              <tr>
                <th
                  className="datalist__header bold--md pl-25 pr-25"
                  scope="row"
                >
                  Definition Link(s):
                </th>
                <td className="datalist__item pl-25 pr-25">
                  <ul className="mt-5">
                    {props.link.map((item, index) => (
                      <li key={uuid() + index}>
                        <a
                          className="link-btn--light"
                          href={urlValidator(item.url)}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {item.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            )}

            <tr>
              <th
                className="datalist__header bold--md pl-25 pr-25 datalist__header--last"
                scope="row"
              >
                Auxiliary verb:
              </th>
              <td className="datalist__item pl-25 pr-25 datalist__item--last">
                {Number(props.auxiliary) === 0 ? "No" : "Yes"}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="mb-50">
          <h4 className="bold--lg mb-5">Present Tense:</h4>
          <h5>Singular</h5>
          {console.log(props.present.singular)}
          <DataList lang="de" list={propPresSingular} type="drag" />
          <h5 className="mt-25">Plural</h5>
          <DataList lang="de" list={propPresPlural} type="drag" />
        </div>

        <div className="mb-50">
          <h4 className="bold--lg mb-5">Past Tense:</h4>
          <h5>Singular</h5>
          <DataList
            lang="de"
            list={propPastSingular}
            type="drag"
          />
          <h5 className="mt-25">Plural</h5>
          <DataList lang="de" list={propPastPlural} type="drag" />
        </div>

        <div className="mb-50">
          <h4 className="bold--lg mb-5">Perfect Tense:</h4>
          <DataList
            lang="de"
            list={{
              participle: props.perfect.spelling,
              helper: props.helper
            }}
            type="drag"
          />
        </div>
      </div>
    </section>
  );
};

Verb.propTypes = {
  index: PropTypes.number.isRequired
};

export default Verb;
