import { DragDropActions } from "../types";
const { UPDATE_DROPPED_ITEMS, UPDATE_DROPPED_ID } = DragDropActions;

export const updateDroppedItems = (payload) => ({
  type: UPDATE_DROPPED_ITEMS,
  payload,
});

export const updateDroppedID = (payload) => ({
  type: UPDATE_DROPPED_ID,
  payload,
});
