import { SearchFilterActions } from "../types";
const {
  ALPHABETICAL_ADD_FILTER,
  ALPHABETICAL_REMOVE_FILTER,
  CLEAR_FILTERED_LIST,
  POSFILTER_ADD_FILTER,
  POSFILTER_REMOVE_FILTER,
  RESET_SEARCH_FILTER,
  UPDATE_FILTERED_LIST
} = SearchFilterActions;

export const addAlphabeticalFilter = payload => ({
  type: ALPHABETICAL_ADD_FILTER,
  payload
});

export const removeAlphabeticalFilter = () => ({
  type: ALPHABETICAL_REMOVE_FILTER
});

export const clearFilteredList = () => ({
  type: CLEAR_FILTERED_LIST
});

export const addPOSFilter = payload => ({
  type: POSFILTER_ADD_FILTER,
  payload
});

export const removePOSFilter = payload => ({
  type: POSFILTER_REMOVE_FILTER,
  payload
});

export const resetSearchFilter = () => ({
  type: RESET_SEARCH_FILTER
});

export const updateFilteredList = payload => ({
  type: UPDATE_FILTERED_LIST,
  payload
});
