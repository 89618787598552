import { RouteActions } from "../../actions/types";
const {
  UPDATE_ASSIGNMENT_ID,
  UPDATE_COURSE_ID,
  UPDATE_SECTION_ID
} = RouteActions;

const initialState = {
  assignmentID: "",
  courseID: "",
  sectionID: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ASSIGNMENT_ID:
      return {
        ...state,
        assignmentID: action.payload
      };
    case UPDATE_COURSE_ID:
      return {
        ...state,
        courseID: action.payload
      };
    case UPDATE_SECTION_ID:
      return {
        ...state,
        sectionID: action.payload
      };
    default:
      return state;
  }
}
