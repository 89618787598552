import React, { Component } from "react";
import { connect } from "react-redux";
import PageTemplate from "../../components/PageTemplate";
import DataList from "../../components/DataList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateBackButton } from "../../actions/pages/assignmentActions";
import { withRouter } from "react-router-dom";
import ConstructSentence from "./ConstructSentence";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

class Directions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showComponent: false,
    };
    this._onButtonClick = this._onButtonClick.bind(this);
  }

  _onButtonClick() {
    this.setState({
      showComponent: true,
    });
  }

  render() {
    const assignmentType = {
      VOCAB: "vocab list",
      PASSAGE: "passage",
      FREEFORM: "free form"
    };

    if (this.state.showComponent) {
      return <ConstructSentence />;
    }

    let expectedTouchScreen = false;

    if (window.matchMedia("(max-width: 1024px)").matches) {
      expectedTouchScreen = true;
    }

    return (
      <>
      {this.props.assignment.type === assignmentType.FREEFORM && (
      <PageTemplate title="Directions">
        <div className="flex nowrap space-between">
          <h2>Directions:</h2> 
            <button
              className="primary-btn bold--md"
              onClick={this._onButtonClick}
            >
              Next
              <FontAwesomeIcon className="ml-10" icon="arrow-right" />
            </button>
        </div>

        <div>{this.props.assignment.directions}</div>

        <h2 className="mt-25">Positions:</h2>
        <DataList list={this.props.positions} />
        <article>
          <h2 className="mt-25">How to use:</h2>
          <h3>Dragging Words:</h3>
          <ol>
            <li>
              {`The Start Language tab at the top contains words or text specific to this assignment.`}
            </li>
            <li>
              Each word that has a drag handle next to it ({" "}
              <FontAwesomeIcon
                className="dnd__draggable-icon mr-0"
                icon="grip-vertical"
              />{" "}
              ) can be clicked on or dragged
              <ul>
                <li>
                Clicking on the word will show its definition and related grammatical forms (which are also draggable).
                  {this.props.assignment.type === assignmentType.PASSAGE && (
                    <ul>
                      <li>
                        If the definition is not found for that word then the
                        closest match will be displayed.
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                Dragging the word down will automatically reveal the Your Language tab.
                </li>
              </ul>
            </li>
          </ol>
          <h3>Building Your Language:</h3>
          <ol>
            <li>
            The Your Language tab at the bottom is where you build your language according to position definitions and can be toggled by clicking on the Down to Your Language button at the bottom of the Start Language tab or by dragging a word down. 
            </li>
            <li>
            After dragging a word into a position in the Your Language tab, you can edit it by clicking on the word or move it from one position to another as needed.
              <ul>
                <li>
                Alternatively, you can edit the entire position by clicking on the position number.
                </li>
              </ul>
            </li>
            <li>To delete a word, drag it to the trash can.</li>
          </ol>
          <h3>Dragging Multiple Words:</h3>
          <ol>
            <li>In the Start Language tab, you can use the Phrase Builder to select multiple words as a single draggable item.</li>
            <li>
            Use the Build Your Phrase button to toggle phrase building mode. With phrase building mode active, clicking any words will add them to the draggable phrase (or group of words).
            </li>
            {expectedTouchScreen && (
              <li>
                Holding the Shift key while clicking a word will automatically add the word to the end of your phrase. If you have not started a phrase yet, the phrase builder tray will appear at this time.
              </li>
            )}
            {!expectedTouchScreen && (
              <li>
                Holding the Shift key while clicking a word will automatically
                add the word to the end of your phrase. If you have not started
                a phrase yet, the phrase builder tray will appear at this time.
              </li>
            )}
          </ol>

          <h3>Use the Your Language by Positions View:</h3>
          
          <ul>
            <li>
            When you are in the Start Language tab, you can still see Your Language by Position at the top.
            </li>
            {this.props.gradeType === "assessment" && (
              <>
                  <li>
                    When finished, click on the email icon ({" "}
                    <FontAwesomeIcon icon={faPaperPlane} /> ) to send your language to the instructor.
                  </li>
                  <li>
                    The submit button will send your assignment to the grader.
                    Only submit when you are completely finished.
                  </li>
              </>
            )}

            {this.props.gradeType === "practice" && (
              <>
                  <li>
                    Click on the clipboard icon ({" "}
                    <FontAwesomeIcon icon="clipboard" /> ) to copy your language to the clipboard.
                  </li>
              </>
            )}
        </ul>

          <h3>Accessibility Directions:</h3>
          <ul>
            <li>
              When selecting a word, pressing alt + enter will provide more information about that word.
            </li>
            <li>
              To drag and drop a word with the keyboard:
              <ol>
                <li>
                  First open the tray and select which position to drop into.
                </li>
                <li>
                  Press the enter key, this will place focus at the top of the assignment.
                </li>
                <li>
                  Find the word you want to drag and press the enter key, this will copy the word into the position selected.
                </li>
              </ol>
            </li>
          </ul>
          <div className="flex flex-end">
            <button
              className="primary-btn bold--md"
              onClick={this._onButtonClick}
            >
              Next
              <FontAwesomeIcon className="ml-10" icon="arrow-right" />
            </button>
          </div>
        </article>
      </PageTemplate>)}
      

{(this.props.assignment.type === assignmentType.VOCAB || this.props.assignment.type === assignmentType.PASSAGE) && (

      <PageTemplate title="Directions">

        <div className="flex nowrap space-between">
          <h2>Directions:</h2> 
            <button
              className="primary-btn bold--md"
              onClick={this._onButtonClick}
            >
              Next
              <FontAwesomeIcon className="ml-10" icon="arrow-right" />
            </button>
        </div>

        <div>{this.props.assignment.directions}</div>

        <h2 className="mt-25">Positions:</h2>
        <DataList list={this.props.positions} />
        <article>
          <h2 className="mt-25">How to use:</h2>
          <h3>Dragging Words:</h3>
          <ol>
            <li>
              {`The ${this.props.assignment.type} tab contains words specific to this assignment`}
            </li>
            <li>
              Each word that has a drag handle next to it ({" "}
              <FontAwesomeIcon
                className="dnd__draggable-icon mr-0"
                icon="grip-vertical"
              />{" "}
              ) can be clicked on or dragged
              <ul>
                <li>
                  Clicking on the word will show its definition and other
                  information
                  {this.props.assignment.type === assignmentType.PASSAGE && (
                    <ul>
                      <li>
                        If the definition is not found for that word then the
                        closest match will be displayed
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  Dragging the word will automatically reveal the sentence tray
                </li>
              </ul>
            </li>
          </ol>
          <h3>Using the Sentence Tray:</h3>
          <ol>
            <li>
              The sentence tray is where you construct your sentence and can be
              toggled by clicking on the Your Positions button near the bottom
              of the screen or by dragging a word
            </li>
            <li>
              After dragging a word into the sentence tray, you can edit it by
              clicking on the word
              <ul>
                <li>
                  Alternatively, you can edit the entire position by clicking on
                  the position number
                </li>
              </ul>
            </li>
            <li>To delete a word, drag it to the trashcan</li>
          </ol>
          <h3>Building a phrase:</h3>
          <ol>
            <li>You can build phrases which are their own draggable items.</li>
            <li>
              Use the Build Your Phrase button to toggle phrase building mode.
              With phrase building mode active, clicking words will add them to
              the phrase.
            </li>
            {expectedTouchScreen && (
              <li>
                Touching and holding a word briefly then releasing it also
                toggles phrase building mode. This word will automatically be
                added to the start of your phrase.
              </li>
            )}
            {!expectedTouchScreen && (
              <li>
                Holding the Shift key while clicking a word will automatically
                add the word to the end of your phrase. If you have not started
                a phrase yet, the phrase builder tray will appear at this time.
              </li>
            )}
          </ol>

          {this.props.gradeType === "assessment" && (
            <>
              <h3>Submitting Your Assignment:</h3>
              <ol>
                <li>
                  When finished, click on the paper airplane icon ({" "}
                  <FontAwesomeIcon icon={faPaperPlane} /> ) to review your work
                  so far.
                </li>
                <li>
                  The submit button will send your assignment to the grader.
                  Only submit when you are completely finished.
                </li>
              </ol>
            </>
          )}

          {this.props.gradeType === "practice" && (
            <>
              <h3>Copying Sentence To Clipboard:</h3>
              <ul>
                <li>
                  Click on the clipboard icon ({" "}
                  <FontAwesomeIcon icon="clipboard" /> ) to copy your sentence
                  to the clipboard.
                </li>
              </ul>
            </>
          )}

          <h3>Accessibility Directions:</h3>
          <ul>
            <li>
              When selecting a word, pressing alt + enter will provide more
              information about that word
            </li>
            <li>
              To drag and drop a word with the keyboard:
              <ol>
                <li>
                  First open the tray and select which position to drop into
                </li>
                <li>
                  Press the enter key, this will place focus at the top of the
                  assignment
                </li>
                <li>
                  Find the word you want to drag and press the enter key, this
                  will copy the word into the position selected
                </li>
              </ol>
            </li>
          </ul>
          <div className="flex flex-end">
            <button
              className="primary-btn bold--md"
              onClick={this._onButtonClick}
            >
              Next
              <FontAwesomeIcon className="ml-10" icon="arrow-right" />
            </button>
          </div>
        </article>
      </PageTemplate>
)}

      </>




      
    );
  }
}

const mapStateToProps = (state) => ({
  assignment: state.get.assignment,
  positions: state.assignment.positionsObj,
  gradeType: state.get.assignment.grade,
});

const mapDispatchToProps = (dispatch) => ({
  updateBackButton: (value) => dispatch(updateBackButton(value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Directions)
);
