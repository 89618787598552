import { AssignmentActions } from "../types";
const {
  RESET_ASSIGNMENT,
  UPDATE_DRAG_CLONE,
  UPDATE_POSITIONS,
  UPDATE_SENTENCE,
  UPDATE_ASSIGNMENT_NAME,
  UPDATE_BACK_BUTTON,
} = AssignmentActions;

export const resetAssignment = () => ({
  type: RESET_ASSIGNMENT,
});

export const updateDragClone = (payload) => ({
  type: UPDATE_DRAG_CLONE,
  payload,
});

export const updatePositions = (obj, arr) => ({
  type: UPDATE_POSITIONS,
  obj,
  arr,
});

export const updateSentence = (position, arr, id) => ({
  type: UPDATE_SENTENCE,
  position,
  arr,
  id,
});

export const updateAssignmentName = (payload) => ({
  type: UPDATE_ASSIGNMENT_NAME,
  payload,
});

export const updateBackButton = (payload) => ({
  type: UPDATE_BACK_BUTTON,
  payload,
});
