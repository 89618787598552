import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { getAllAssignments } from "../actions/HTTP-requests/getActions";
import { updateAssignmentName } from "../actions/pages/assignmentActions";
import { getSectionAssignments } from "../actions/HTTP-requests/getActions";
import moment from "moment";
import PageTemplate from "../components/PageTemplate";
import AssignmentCards from "../components/AssignmentCards";

class Assignments extends Component {
  state = {
    assignments: [],
    noAssignments: false,
    modules: [],
    start: [],
    due: [],
    filter: [],
    selectedFilter: "",
    courseName: "",
    sectionName: "",
    courseid: "",
    sectionid: "",
  };

//  componentDidMount() {
//    this.props.getAllAssignments().then(() => this.findAssignments());
//  }

componentDidMount() {
  const sectionId = (new URLSearchParams(this.props.location.search)).get("s");
  this.props.getSectionAssignments(sectionId).then(() => this.findSectionAssignments());
}

  componentWillUnmount() {
    this.props.updateAssignmentName("");
  }

//  findAssignments = () => {
//    const { allAssignments } = this.props;
//    const params = new URLSearchParams(this.props.location.search);
//    const matchedAssignments = allAssignments.filter((assignment) => {
//      if (
//        assignment.course.id === params.get("c") &&
//        assignment.section.id === params.get("s")
//      ) {
//        return true;
//      }
//      return false;
//    });

//    if (matchedAssignments.length <= 0) {
//      return this.setState({ noAssignments: true });
//    } else {
//      return this.setState(
//        {
//          courseid: params.get("c"),
//          sectionid: params.get("s"),
//        },
//        () => this.parseAssignments(matchedAssignments)
//      );
//    }
//
//    //return this.parseAssignments(matchedAssignments);
//  };

findSectionAssignments = () => {  
  const { sectionAssignments } = this.props;
  const params = new URLSearchParams(this.props.location.search);
  const matchedAssignments = (sectionAssignments.filter((assignment) => {
    if (
      assignment.course.id === params.get("c") &&
      assignment.section.id === params.get("s")
    ) {
      return true;
    }
    return false;
  }));
    if (matchedAssignments.length <=0) {
      return this.setState({ noAssignments: true });
    } else {
      return this.setState(
            {
              courseid: params.get("c"),
              sectionid: params.get("s"),
            },
            () => this.parseAssignments(matchedAssignments)
          );
        }
    };


  parseAssignments = (matchedAssignments) => {
    let obj = {
      firstDate: "",
      lastDate: "",
      modules: {},
      startObj: {},
      dueObj: {},
    };

    matchedAssignments.forEach((assignment) => {
      // Add by Dates
      const start = assignment.start && moment.unix(assignment.start / 1000);
      obj = this.addAssignmentByDate(assignment, start, "startObj", obj);
      const due = assignment.due && moment.unix(assignment.due / 1000);
      obj = this.addAssignmentByDate(assignment, due, "dueObj", obj);

      //Add by Modules
      const module = assignment.module.name;
      obj.modules[module] = obj.modules[module]
        ? [...obj.modules[module], assignment]
        : [assignment];
    });

    obj = this.addDateLabels(obj);

    obj.due = this.organizeAssignments(obj.dueObj);
    obj.modules = this.organizeAssignments(obj.modules, true);
    obj.start = this.organizeAssignments(obj.startObj);

    return this.setState({
      assignments: matchedAssignments,
      noAssignments: matchedAssignments.length === 0 ? true : false,
      due: obj.due,
      modules: obj.modules,
      start: obj.start,
      filter: obj.modules,
      selectedFilter: "modules",
      courseName: matchedAssignments[0].course.name,
      sectionName: matchedAssignments[0].section.name,
    });
  };

  addAssignmentByDate = (assignment, date, key, obj) => {
    // if date is valid then add assignment to startObj or dueObj array
    if (date) {
      // validate if start or due date are end points for this course
      if (!obj.lastDate || date.isAfter(obj.lastDate)) obj.lastDate = date;
      if (!obj.firstDate || date.isBefore(obj.firstDate)) obj.firstDate = date;

      let week = obj[key][date.isoWeek()];
      obj[key] = {
        ...obj[key],
        [date.isoWeek()]: {
          ...week,
          assignments:
            week && week.assignments
              ? [...week.assignments, assignment]
              : [assignment],
        },
      };
    } else {
      //if date is invalid then assignment belongs to no exp.
      obj[key] = {
        ...obj[key],
        noexp: {
          ...obj[key].noexp,
          assignments:
            obj[key].noexp && obj[key].noexp.assignments
              ? [...obj[key].noexp.assignments, assignment]
              : [assignment],
          label: key === "dueObj" ? "No Expiration Date" : "No Start Date",
        },
      };
    }

    return obj;
  };

  addDateLabels = (obj) => {
    if (obj.firstDate && obj.lastDate) {
      for (let i = obj.firstDate.isoWeek(); i <= obj.lastDate.isoWeek(); i++) {
        const mon = moment().week(i).startOf("isoWeek").format("MMM DD, YYYY");

        const sun = moment().week(i).endOf("isoWeek").format("MMM DD, YYYY");

        if (obj.startObj[i] !== undefined) {
          obj.startObj[i].label = `${mon} - ${sun}`;
        }

        if (obj.dueObj[i] !== undefined) {
          obj.dueObj[i].label = `${mon} - ${sun}`;
        }

        // obj.startObj[i].label = `${mon} - ${sun}`;
        // obj.dueObj[i].label = `${mon} - ${sun}`;
      }
    }
    return obj;
  };

  organizeAssignments = (obj, isModule) => {
    const arr = Object.keys(obj);
    const keys = arr.sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
    );

    return keys.map((key) => ({
      label: isModule ? key : obj[key].label,
      assignments: isModule ? obj[key] : obj[key].assignments,
    }));
  };

  handleFilter = (key) =>
    this.setState({
      filter: this.state[key],
      selectedFilter: key,
    });

  render() {
    const {
      assignments,
      // courseName,
      filter,
      // sectionName,
      selectedFilter,
      noAssignments,
    } = this.state;

    const mobile = window.matchMedia("(max-width: 1024px)").matches;

    if (noAssignments) {
      return (
        <PageTemplate title="Assignments">
          <div className="bold--md mb-25 center-text">
            No assignments available for this course/section.
          </div>
        </PageTemplate>
      );
    } else if (assignments.length <= 0) {
      return (
        <PageTemplate title="Assignments">
          <div className="loader">Loading...</div>
        </PageTemplate>
      );
    }

    if (mobile) {
      return (
        <PageTemplate title="Assignments">
          <React.Fragment>
            <div className="flex space-between wrap inset">
              <div>
                {/* <p className="mb-5">
                  <span className="bold--lg">Course: </span>
                  <span className="reg--lg">{courseName}</span>
                </p>
                <p>
                  <span className="bold--lg">Section: </span>
                  <span className="reg--lg">{sectionName}</span>
                </p> */}
                <span className="bold--lg">Choose Your Assignment</span>
                <p className="bold--md mb-5">Sort by:</p>
              </div>
              <div
                style={{
                  display: "inline-flex",
                  whiteSpace: "nowrap",
                }}
              >
                <button
                  style={{ paddingRight: "25px", paddingLeft: "25px" }}
                  className={`
                  bold--md
                  ${
                    selectedFilter === "modules"
                      ? "group-btn__left--selected"
                      : "group-btn__left"
                  }
                `}
                  onClick={() => this.handleFilter("modules")}
                >
                  Modules
                </button>
                <button
                  className={`
                bold--md
                ${
                  selectedFilter === "due"
                    ? "group-btn__mid--selected"
                    : "group-btn__mid"
                }
              `}
                  onClick={() => this.handleFilter("due")}
                >
                  Due Date
                </button>
                <button
                  className={`
                bold--md
                ${
                  selectedFilter === "start"
                    ? "group-btn__right--selected"
                    : "group-btn__right"
                }
              `}
                  onClick={() => this.handleFilter("start")}
                >
                  Start Date
                </button>
              </div>
            </div>

            <AssignmentCards
              items={filter}
              courseid={this.state.courseid}
              sectionid={this.state.sectionid}
              {...this.props}
            />
          </React.Fragment>
        </PageTemplate>
      );
    }

    return (
      <PageTemplate title="Assignments">
        <React.Fragment>
          <div className="flex space-between wrap inset">
            <div>
              <span className="bold--lg">Choose Your Assignment</span>
              <p className="bold--md mb-5">Sort by:</p>
              <div>
                <button
                  className={`
                bold--md
                ${
                  selectedFilter === "modules"
                    ? "group-btn__left--selected"
                    : "group-btn__left"
                }
              `}
                  onClick={() => this.handleFilter("modules")}
                >
                  Modules
                </button>
                <button
                  className={`
              bold--md
              ${
                selectedFilter === "due"
                  ? "group-btn__mid--selected"
                  : "group-btn__mid"
              }
            `}
                  onClick={() => this.handleFilter("due")}
                >
                  Due Date
                </button>
                <button
                  className={`
              bold--md
              ${
                selectedFilter === "start"
                  ? "group-btn__right--selected"
                  : "group-btn__right"
              }
            `}
                  onClick={() => this.handleFilter("start")}
                >
                  Start Date
                </button>
              </div>
            </div>
          </div>

          <AssignmentCards
            items={filter}
            courseid={this.state.courseid}
            sectionid={this.state.sectionid}
            {...this.props}
          />
        </React.Fragment>
      </PageTemplate>
    );
  }
}

//  const mapStateToProps = (state) => ({
//    allAssignments: state.get.allAssignments,
//  });

//  const mapDispatchToProps = (dispatch) => ({
//   getAllAssignments: async () => dispatch(getAllAssignments()),
//    updateAssignmentName: (value) => dispatch(updateAssignmentName(value)),
//  });

const mapStateToProps = (state) => ({
  sectionAssignments: state.get.sectionAssignments,
});

const mapDispatchToProps = (dispatch) => ({
  getSectionAssignments: async (value) => dispatch(getSectionAssignments(value)),
  updateAssignmentName: (value) => dispatch(updateAssignmentName(value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Assignments)
);
