import { SearchFilterActions } from "../../actions/types";
import uuid from "uuid";
const {
  ALPHABETICAL_ADD_FILTER,
  ALPHABETICAL_REMOVE_FILTER,
  CLEAR_FILTERED_LIST,
  POSFILTER_ADD_FILTER,
  POSFILTER_REMOVE_FILTER,
  RESET_SEARCH_FILTER,
  UPDATE_FILTERED_LIST
} = SearchFilterActions;

const initialState = {
  AlphabeticalFilter: {
    appliedFilters: [],
    bodyID: uuid(),
    headingID: uuid(),
    name: "Alphabetical",
    values: [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "ä",
      "ö",
      "ü",
      "ß"
    ]
  },
  filteredList: [],
  POSFilter: {
    appliedFilters: [],
    bodyID: uuid(),
    headingID: uuid(),
    name: "Part of Speech",
    values: ["article", "modifier", "preposition", "noun", "pronoun", "verb"]
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ALPHABETICAL_ADD_FILTER:
      return {
        ...state,
        AlphabeticalFilter: {
          ...state.AlphabeticalFilter,
          appliedFilters: [action.payload]
        }
      };
    case ALPHABETICAL_REMOVE_FILTER:
      return {
        ...state,
        AlphabeticalFilter: {
          ...state.AlphabeticalFilter,
          appliedFilters: []
        }
      };
    case CLEAR_FILTERED_LIST:
      return {
        ...state,
        filteredList: []
      };
    case POSFILTER_ADD_FILTER:
      return {
        ...state,
        POSFilter: {
          ...state.POSFilter,
          appliedFilters: [...state.POSFilter.appliedFilters, action.payload]
        }
      };
    case POSFILTER_REMOVE_FILTER:
      return {
        ...state,
        POSFilter: {
          ...state.POSFilter,
          appliedFilters: state.POSFilter.appliedFilters.filter(
            item => item !== action.payload
          )
        }
      };
    case RESET_SEARCH_FILTER:
      return {
        ...state,
        AlphabeticalFilter: {
          ...state.AlphabeticalFilter,
          appliedFilters: []
        },

        POSFilter: {
          ...state.POSFilter,
          appliedFilters: []
        }
      };
    case UPDATE_FILTERED_LIST:
      return {
        ...state,
        filteredList: action.payload
      };
    default:
      return state;
  }
}
