import { GetActions, SearchFilterActions } from "../types";
import { apiParser } from "../../utils/httpUtils";
import axios from "axios";
const {
  GET_ALL_ASSIGNMENTS,
  GET_SECTION_ASSIGNMENTS,
  GET_ALL_WORDS,
  GET_COURSES,
  GET_SPECIFIC_ASSIGNMENT,
  GET_SPECIFIC_WORD
  // SESSION_EXPIRED
} = GetActions;
const { UPDATE_FILTERED_LIST } = SearchFilterActions;

export const getAllAssignments = () => {
  const API_HOST_URL = apiParser("assignment-student");
  const request = axios.get(API_HOST_URL);

  return (dispatch) =>
    request.then(({ data }) => {
      dispatch({
        type: GET_ALL_ASSIGNMENTS,
        payload: data
      });
    });
};

export const getSectionAssignments = (payload) => {
  const API_HOST_URL = apiParser("assignment-section", {
    param2: payload
  });
  const request = axios.get(API_HOST_URL);
  
  return (dispatch) => 
    request.then(({ data }) => {
      dispatch({
        type: GET_SECTION_ASSIGNMENTS,
        payload: data 
      });
    });
};

export const getAssignment = (payload) => (dispatch) => {
  const API_HOST_URL = apiParser("assignment-student", {
    param2: payload
  });

  try {
    const request = axios.get(API_HOST_URL, {
      timeout: 5000
    });

    return request
      .then((res) => {
        return dispatch({
          type: GET_SPECIFIC_ASSIGNMENT,
          payload: res.data[0]
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const getAllCourses = () => {
  const API_HOST_URL = apiParser("course");
  const request = axios.get(API_HOST_URL);

  return (dispatch) =>
    request.then(({ data }) => {
      dispatch({
        type: GET_COURSES,
        payload: data
      });
    });
};

export const getAllWords = () => {
  const API_HOST_URL = apiParser("word");
  const request = axios.get(API_HOST_URL);

  return (dispatch) =>
    request.then(({ data }) => {
      dispatch({
        type: GET_ALL_WORDS,
        payload: data.words
      });

      dispatch({
        type: UPDATE_FILTERED_LIST,
        payload: data.words
      });
    });
};

export const getSpecificWord = (payload) => {
  const API_HOST_URL = apiParser("word", {
    param2: `spelling?spelling=${payload}`,
    devParam: "&"
  });

  const request = axios.get(`${API_HOST_URL}`);

  return (dispatch) =>
    request.then(({ data }) => {
      dispatch({
        type: GET_SPECIFIC_WORD,
        payload: data
      });
    });
};
