export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const toSuperscript = (string) => {
  let super_sub_script_dict = {
    "0": "\u2070",
    "1": "\u00B9",
    "2": "\u00B2",
    "3": "\u00B3",
    "4": "\u2074",
    "5": "\u2075",
    "6": "\u2076",
    "7": "\u2077",
    "8": "\u2078",
    "9": "\u2079",
  };
  let regex = new RegExp(
    "[" + Object.keys(super_sub_script_dict).join("") + "]",
    "g"
  );

  return string.replace(regex, function (character) {
    return super_sub_script_dict[character];
  });
};
