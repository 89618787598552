import React from "react";
import PropTypes from "prop-types";

const Dropdown = props => {
  const { ariaLabel, className, controlSelectedOption, options } = props;
  return (
    <select
      aria-label={ariaLabel}
      className={className}
      onChange={controlSelectedOption}
    >
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

Dropdown.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  controlSelectedOption: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

export default Dropdown;
