import React, { Component } from "react";
import { connect } from "react-redux";
import {
  updateBottomGrid,
  updateDeleteGrid,
  updateTrayToggle,
} from "../../actions/components/trayActions";
import { updateSentence } from "../../actions/pages/assignmentActions";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import DeleteContainer from "../DragAndDrop/DeleteContainer";
import Containers from "./Containers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Tray extends Component {
  sentenceTray = React.createRef();
  body = null;

  componentDidMount() {
    this.body = document.getElementsByTagName("body");
    document.addEventListener("keydown", this.handleTrayToggleKeyPress);
    document.addEventListener("contextmenu", this.handleTrayToggleClick);
  }

  handleTrayToggleKeyPress = (event) => {
    const { updateTrayToggle, toggle } = this.props;
    if (event.key === "ArrowUp" && event.altKey) {
      updateTrayToggle(!toggle);
    }
  };

  handleTrayToggleClick = (event) => {
    const { updateTrayToggle, toggle } = this.props;
    if (event.type === "contextmenu") {
      updateTrayToggle(!toggle);
    }
  };

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  componentDidUpdate() {
    const displayedSentence = this.sentenceTray.current;
    if (displayedSentence) {
      displayedSentence.style.height = `${displayedSentence.scrollHeight}px`;
    }
  }

  handleToggle = () => {
    const { updateTrayToggle, toggle } = this.props;
    toggle ? enableBodyScroll(this.body) : disableBodyScroll(this.body);
    return updateTrayToggle(!toggle);
  };

  render() {
    const assignmentType = {
      VOCAB: "vocab list",
      PASSAGE: "passage",
      FREEFORM: "free form",
    };

    return (
      <section className="tray">
        <button
          className="tray__toggle-btn bold--md"
          onClick={this.handleToggle}
        >
          <FontAwesomeIcon
            className="tray__toggle-icon"
            icon={this.props.toggle ? "chevron-down" : "chevron-up"}
            size="1x"
          />
          {this.props.assignment.type === assignmentType.FREEFORM
            ? this.props.toggle
              ? "Down to your Language"
              : "Up to Start Language"
            : "Your Positions"}
        </button>

        <article
          id="tray-body"
          className={`tray__body ${this.props.toggle && "tray__body--show"}`}
        >
          <div id="tray-container" className="tray__container">
            <div className="tray__wrapper">
              <Containers
                controlGrid={this.props.updateBottomGrid}
                deleteGrid={this.props.updateDeleteGrid}
                positions={this.props.positions}
              />
            </div>
          </div>

          <div className="pb-5 pt-15">
            <DeleteContainer
              gridInstance={(value) => this.props.updateDeleteGrid(value)}
              parentID="tray-body"
            />
          </div>
        </article>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  bottomGrid: state.tray.bottomGrid,
  positions: state.assignment.positionsArr,
  sentence: state.assignment.sentence,
  toggle: state.tray.toggle,
  assignmentName: state.get.assignment.name,
  assignment: state.get.assignment,
});

const mapDispatchToProps = (dispatch) => ({
  updateBottomGrid: (value) => dispatch(updateBottomGrid(value)),
  updateDeleteGrid: (value) => dispatch(updateDeleteGrid(value)),
  updateSentence: (sentenceID, arr, gridID) =>
    dispatch(updateSentence(sentenceID, arr, gridID)),
  updateTrayToggle: (value) => dispatch(updateTrayToggle(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tray);
