import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import {
  getSectionAssignments,
  getAssignment,
} from "../../actions/HTTP-requests/getActions";
import {
  resetAssignment,
  updatePositions,
  updateBackButton,
} from "../../actions/pages/assignmentActions";
import {
  updateCourseID,
  updateSectionID,
} from "../../actions/routes/routeActions";
import { updateRenderTray } from "../../actions/components/trayActions";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Directions from "./Directions";
import ConstructSentence from "./ConstructSentence";
import PageTemplate from "../../components/PageTemplate";

class Assignment extends Component {
  state = {
    assignmentLoaded: false,
    bypassDirections: false,
    validAssignment: true,
    step: 0,
  };

  componentDidMount() {
    const sectionId = (new URLSearchParams(this.props.location.search)).get("s");
    this.props.getSectionAssignments(sectionId).then(() => this.validAssignment());
  }

  componentWillUnmount() {
    this.props.resetAssignment();
    this.props.updateRenderTray(false);
  }

  validAssignment = () => {
    const params = new URLSearchParams(this.props.location.search);
    const {
      sectionAssignments,
      getAssignment,
      updateCourseID,
      updateSectionID,
    } = this.props;
    const valid = sectionAssignments.some((assignment) => {
      if (assignment.id === params.get("a")) {
        if (
          assignment.course.id === params.get("c") &&
          assignment.section.id === params.get("s")
        ) {
          return true;
        }
      }
      return false;
    });

    if (valid) {
      updateCourseID(params.get("c"));
      updateSectionID(params.get("s"));
      return getAssignment(params.get("a"))
        .then(() => this.createPositionObj())
        .then(() =>
          this.setState({
            assignmentLoaded: true,
            step: params.get("bypass") === "true" ? 1 : 0,
          })
        );
    } else {
      return this.setState({
        validAssignment: false,
      });
    }
  };

  createPositionObj = () => {
    const { assignment, updatePositions } = this.props;
    let counter = 0;
    let arr = [];
    let obj = {};

    for (let i = 0; i < assignment.position.length; i++) {
      const desc = assignment.position[i].description;
      let punc = assignment.position[i].punctuation;

      if (punc !== "none") {
        punc = punc === "period" ? "." : ",";
      } else {
        punc = null;
      }

      counter++;
      arr.push({ num: counter, desc, punc });
      obj[counter] = desc;
    }

    return updatePositions(obj, arr);
  };

  renderStep = () => {
    switch (this.state.step) {
      case 0:
        return <Directions />;
      case 1:
        return <ConstructSentence />;
      default:
        return;
    }
  };

  addToStep = () => this.setState({ step: this.state.step + 1 });

  subtractFromStep = () => this.setState({ step: this.state.step - 1 });

  render() {
    // const { assignmentLoaded, step, validAssignment } = this.state;
    const { assignmentLoaded, validAssignment } = this.state;

    // const firstStep = step === 0;

    if (this.props.sessionExpired) {
      return <Redirect to={`/`} />;
    }

    if (!validAssignment) {
      return <Redirect to={`/`} />;
    } else if (!assignmentLoaded) {
      return (
        <PageTemplate title='Directions'>
          <div className='loader'>Loading...</div>
        </PageTemplate>
      );
    }

    if (this.props.backButtonClick) {
      return <Directions />;
    }

    // if (!firstStep && this.props.backButtonClick) {
    //   return (
    //     <div>
    //       <div className='flex flex-end'>
    //         <button className='primary-btn bold--md' onClick={this.addToStep}>
    //           Next
    //           <FontAwesomeIcon className='ml-10' icon='arrow-right' />
    //         </button>
    //       </div>
    //       <Directions />
    //     </div>
    //   );
    // } else {

    return (
      <div>
        {this.renderStep()}

        {/* {firstStep && (
          <div className='flex flex-end'>
            <button className='primary-btn bold--md' onClick={this.addToStep}>
              Next
              <FontAwesomeIcon className='ml-10' icon='arrow-right' />
            </button>
          </div>
        )} */}

        {/* {!firstStep && (
          <button
            className='secondary-btn bold--md mr-25'
            onClick={this.subtractFromStep}>
            <FontAwesomeIcon className='mr-10' icon='arrow-left' />
            Directions
          </button>
        )} */}
      </div>
    );
    // }
  }
}

const mapStateToProps = (state) => ({
  sectionAssignments: state.get.sectionAssignments,
  assignment: state.get.assignment,
  sessionExpired: state.get.sessionExpired,
  backButtonClick: state.assignment.backButtonClick,
});

const mapDispatchToProps = (dispatch) => ({
  getSectionAssignments: async (value) => dispatch(getSectionAssignments(value)),
  getAssignment: async (value) => dispatch(getAssignment(value)),
  resetAssignment: () => dispatch(resetAssignment()),
  updatePositions: (obj, arr) => dispatch(updatePositions(obj, arr)),
  updateCourseID: (value) => dispatch(updateCourseID(value)),
  updateRenderTray: (value) => dispatch(updateRenderTray(value)),
  updateSectionID: (value) => dispatch(updateSectionID(value)),
  updateBackButton: (value) => dispatch(updateBackButton(value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Assignment)
);
