import { DictionaryActions } from "../types";
const {
  DICTIONARY_RESET,
  DICTIONARY_SEARCH_VALUE,
  DICTIONARY_UPDATE_TOGGLE_FILTER
} = DictionaryActions;

export const dictionaryReset = () => ({
  type: DICTIONARY_RESET
});

export const updateSearchValue = payload => ({
  type: DICTIONARY_SEARCH_VALUE,
  payload
});

export const updateToggleFilter = payload => ({
  type: DICTIONARY_UPDATE_TOGGLE_FILTER,
  payload
});
